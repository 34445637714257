import React from 'react';
import { SaveButton } from 'react-admin';

const ToolbarSaveButton = props => (
  <div>
    <SaveButton
      { ...props }
      label='Save'
      redirect={ false }
      submitOnEnter={ true }
    />
  </div>
);

export default ToolbarSaveButton;
