import React, {Children} from 'react';
import {withStyles} from '@material-ui/core/styles';

const AdaptiveLayout = ({children, classes}) => (
  <div className={classes.root}>
    {
      Children.map(children, (child, index) => {
        return (
          <div key={index} className={classes.item}>
            {child}
          </div>
        );
      })
    }
  </div>
);

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: { // eslint-disable-line no-useless-computed-key
      flexDirection: 'row',
    }
  },
  item: {
    flex: 1,
  }
});

export default withStyles(styles)(AdaptiveLayout);
