import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
} from 'react-admin';

import CustomResourceList from 'common/custom-resource-list/CustomResourceList';

import {WorkersFilter, WorkersGrid, workersListProps, WorkersIcon} from 'resources/workers';
import {FarmEventsFilter, FarmEventsGrid, farmEventsListProps, FarmEventsIcon} from 'resources/farm_events';
import {AclFilter, AclGrid, aclListProps, AclIcon} from 'resources/acl';
import {CoinpaymentsFilter, CoinpaymentsGrid, coinpaymentsListProps, CoinpaymentsIcon} from 'resources/coinpayments';
import {TransactionsFilter, TransactionsGrid, transactionsListProps, TransactionsIcon} from 'resources/transactions';
import {ApiKeysFilter, ApiKeysGrid, apiKeysListProps, ApiKeysIcon} from "resources/api_keys";
import {WalletsFilter, WalletsGrid, walletsListProps, WalletsIcon} from "resources/wallets";
import {DepositWalletsFilter, DepositWalletsGrid, depositWalletsListProps, DepositWalletsIcon} from "resources/deposit_wallets";

import Title from 'components/farms/Title';
import EditAttributes from 'components/farms/EditAttributes';
import FarmInfo from "./FarmInfo";
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';

const FarmEdit = ({permissions: p = {}, ...props}) => (
  <Show title={<Title/>} actions={null} {...props}>
    <TabbedShowLayout>
      <Tab label='Info' icon={<InfoIcon/>}>
        <FarmInfo permissions={p}/>
      </Tab>

      {p && p['admin.farms.manage.*'] && <Tab label='Edit' path='edit' icon={<EditIcon/>}>
        <EditAttributes permissions={p} {...props}/>
      </Tab>}

      {p && p['admin.acl'] && <Tab label='ACL' path='acl' icon={<AclIcon/>}>
        <CustomResourceList
          key='custom-resource-list-acl'
          {...props}
          {...aclListProps}
          custom='acl'
          customfilter={{target: 'farm_id', record: 'id'}}
          filters={<AclFilter consumer='farms'/>}
          bulkActionButtons={false}
        >
          <AclGrid permissions={p} consumer='farms'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.farm_events'] && <Tab label='Events' path='farm_events' icon={<FarmEventsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-farm-events'
          {...props}
          {...farmEventsListProps}
          custom='farm_events'
          customfilter={{target: 'farm_id', record: 'id'}}
          filters={<FarmEventsFilter consumer='farms'/>}
          bulkActionButtons={false}
        >
          <FarmEventsGrid permissions={p} consumer='farms'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.workers'] && <Tab label='Workers' path='workers' icon={<WorkersIcon/>}>
        <CustomResourceList
          key='custom-resource-list-workers'
          {...props}
          {...workersListProps}
          custom='workers'
          customfilter={{target: 'farm_id', record: 'id'}}
          filters={<WorkersFilter consumer='farms'/>}
          bulkActionButtons={false}
        >
          <WorkersGrid permissions={p} consumer='farms'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.wallets'] && <Tab label='Wallets' path='wallets' icon={<WalletsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-wallets'
          {...props}
          {...walletsListProps}
          custom='wallets'
          customfilter={{target: 'farm_id', record: 'id'}}
          filters={<WalletsFilter consumer='farms'/>}
          bulkActionButtons={false}
        >
          <WalletsGrid permissions={p} consumer='farms'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.transactions'] && <Tab label='Transactions' path='transactions' icon={<TransactionsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-transactions'
          {...props}
          {...transactionsListProps}
          custom='transactions'
          customfilter={{target: 'farm_id', record: 'id'}}
          filters={<TransactionsFilter consumer='farms'/>}
          bulkActionButtons={false}
        >
          <TransactionsGrid permissions={p} consumer='farms'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.coinpayments'] && <Tab label='Coinpayments' path='coinpayments' icon={<CoinpaymentsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-coinpayments'
          {...props}
          {...coinpaymentsListProps}
          custom='coinpayments'
          customfilter={{target: 'farm_id', record: 'id'}}
          filters={<CoinpaymentsFilter consumer='farms'/>}
          bulkActionButtons={false}
        >
          <CoinpaymentsGrid permissions={p} consumer='farms'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.deposit_addresses'] && <Tab label='Deposit wallets' path='deposit_addresses' icon={<DepositWalletsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-deposit_addresses'
          {...props}
          {...depositWalletsListProps}
          custom='deposit_addresses'
          customfilter={{target: 'farm_id', record: 'id'}}
          filters={<DepositWalletsFilter consumer='farms'/>}
          bulkActionButtons={false}
        >
          <DepositWalletsGrid permissions={p} consumer='farms'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.api_keys'] && <Tab label='API keys' path='api_keys' icon={<ApiKeysIcon/>}>
        <CustomResourceList
          key='custom-resource-list-api_keys'
          {...props}
          {...apiKeysListProps}
          custom='api_keys'
          customfilter={{target: 'farm_id', record: 'id'}}
          filters={<ApiKeysFilter consumer='farms'/>}
          bulkActionButtons={false}
        >
          <ApiKeysGrid permissions={p} consumer='farms'/>
        </CustomResourceList>
      </Tab>}
    </TabbedShowLayout>
  </Show>
);

export default FarmEdit;
