import React from 'react';
import PropTypes from 'prop-types';
import BlockchainUrlField from "./BlockchainUrlField";

const TxidField = (props) => {
  return <BlockchainUrlField
    mode='transaction'
    {...props}
  />;
};

TxidField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  currencySource: PropTypes.string,
};

TxidField.defaultProps = {
  record: {},
  source: 'txid',
  currencySource: 'currency',
  label: 'Transaction hash',
  addLabel: true,
};

export default TxidField;
