import React from 'react';
import PropTypes from 'prop-types';
import { fetchApi } from 'providers/myRestProvider';
import {fetchEnd, fetchStart, showNotification, hideNotification} from 'react-admin';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';

class PayoutButton extends React.Component {
	static propTypes = {
		done: PropTypes.func,
		params: PropTypes.object,
		record: PropTypes.object,
		selectedIds: PropTypes.array,
	};

	handleClick = () => {
		const {record, selectedIds, params} = this.props;
		const {done, fetchStart, fetchEnd} = this.props;
		const data = params || {};

		let promise;
		fetchStart();

		if (selectedIds) {
			promise = this.doPayoutsMulti(selectedIds, data);
		} else {
			promise = this.doPayoutSingle(record, data);
		}

		promise.catch((e) => {
			showNotification(e.message, 'warning')
		}).then(fetchEnd)
			.then(done);
	};

	doPayoutsMulti(selectedIds, data = null) {
		const {showNotification, hideNotification} = this.props;
		let successCount = 0, failCount = 0;
		const totalCount = selectedIds.length;

		const pay = async () => {
			let i = 0;
			for (let id of selectedIds) {
				try {
					await fetchApi(`referrals/${id}/payout`, {method: 'POST', body: data});
					hideNotification();
					showNotification(`Payout ${++i} of ${totalCount}: Transaction sent`);
					successCount++;
				} catch (e) {
					hideNotification();
					showNotification(`Payout ${++i} of ${totalCount}: Fail: ${e.message}`, 'warning');
					failCount++;
					break;
				}
				await new Promise(r => setTimeout(r, 5000));
			}
			hideNotification();
		};

		const processedCount = successCount + failCount;
		return pay().then(() => {
			showNotification(`${processedCount} of ${totalCount} payouts processed. Succeeded: ${successCount}. Failed: ${failCount}.`);
		});
	}

	doPayoutSingle(record, data = null) {
		const {showNotification} = this.props;

		return fetchApi(`referrals/${record.id}/payout`, {method: 'POST', body: data}).then(() => {
			showNotification('Payout transaction sent');
		})
	}

	render() {
		return <Button color="primary" variant="contained" onClick={this.handleClick}>
			{this.props.children || 'Payout'}
		</Button>;
	}
}

export default connect(null, {
	showNotification, hideNotification, fetchStart, fetchEnd
})(PayoutButton);
