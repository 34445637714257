import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AttributeToggle from 'common/attribute-toggle/AttributeToggle';

export default class BanToggle extends Component {
  static propTypes = {
    noButton: PropTypes.bool,
  };

  static defaultProps = {
    noButton: true,
  };

  render() {
    const {props} = this;

    const onBeforeRequest = (req) => {
      if (req['banned']) {
        const reason = prompt('Enter ban reason');
        if (reason === null) {
          // cancelled
          return false;
        }
        req['ban_reason'] = reason;
      }
    };

    return (
      <AttributeToggle
        recordAttribute='banned'
        requestAttribute='banned'
        labelText='Banned'
        enableText='Ban'
        disableText='Unban'
        noButton={ props.noButton }
        editOnTurnedOff={ true }
        record={ props.record }
        resource={ props.resource }
        translate={ props.translate }
        onBeforeRequest={ onBeforeRequest }
      />
    );
  }
}
