import React, {Children, Component} from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import classnames from 'classnames';
import {getDefaultValues, REDUX_FORM_NAME, translate} from 'ra-core';

import {CardContentInner, FormInput, Toolbar} from 'react-admin';
import {extractFormValues} from "./Utils";

/*export default class CustomSimpleForm extends SimpleForm {
  handleSubmitWithRedirect = (redirect = this.props.redirect) => {
    alert('ok');
    return this.props.handleSubmit((values, dispatch, props) => {
      // don't pass the whole record to save()
      const fields = Object.keys(props.registeredFields);
      values = _.pick(values, fields);
      return this.props.save(values, redirect)
    })
  };
}*/


/*
 * Below is a copy-paste of react-admin's SimpleForm.js file.
 *
 * The reason is that we need to modify the data passed to save() function
 * because if the record is changed in state - redux-form's handleSubmit still provide old record.
 *
 * Actually we need to override only one method handleSubmitWithRedirect()
 * but I cannot make it work so to not waste a lot of time I just copied original code here.
 *
 * If you can fix the code above this comment - remove the rest.
 */


const sanitizeRestProps = ({
                             anyTouched,
                             array,
                             asyncBlurFields,
                             asyncValidate,
                             asyncValidating,
                             autofill,
                             blur,
                             change,
                             clearAsyncError,
                             clearFields,
                             clearSubmit,
                             clearSubmitErrors,
                             destroy,
                             dirty,
                             dispatch,
                             form,
                             handleSubmit,
                             initialize,
                             initialized,
                             initialValues,
                             pristine,
                             pure,
                             redirect,
                             reset,
                             resetSection,
                             save,
                             submit,
                             submitFailed,
                             submitSucceeded,
                             submitting,
                             touch,
                             translate,
                             triggerSubmit,
                             untouch,
                             valid,
                             validate,
                             ...props
                           }) => props;

export class CustomSimpleForm extends Component {
  handleSubmitWithRedirect = (redirect = this.props.redirect) => {
    return this.props.handleSubmit((values, dispatch, props) => {
      // don't pass the whole record to save()
      const formValues = extractFormValues(values, props);
      return this.props.save(formValues, redirect)
    })
  };

  render() {
    const {
      basePath,
      children,
      className,
      invalid,
      pristine,
      record,
      redirect,
      resource,
      saving,
      submitOnEnter,
      toolbar,
      version,
      ...rest
    } = this.props;

    return (
      <form
        className={ classnames('simple-form', className) }
        { ...sanitizeRestProps(rest) }
      >
        <CardContentInner key={ version }>
          { Children.map(children, input => (
            <FormInput
              basePath={ basePath }
              input={ input }
              record={ record }
              resource={ resource }
            />
          )) }
        </CardContentInner>
        { toolbar && (
          <CardContentInner>
            { React.cloneElement(toolbar, {
              basePath,
              handleSubmitWithRedirect: this
                .handleSubmitWithRedirect,
              handleSubmit: this.props.handleSubmit,
              invalid,
              pristine,
              record,
              redirect,
              resource,
              saving,
              submitOnEnter,
            }) }
          </CardContentInner>
        ) }
      </form>
    );
  }
}

CustomSimpleForm.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSubmit: PropTypes.func, // passed by redux-form
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  toolbar: PropTypes.element,
  validate: PropTypes.func,
  version: PropTypes.number,
};

CustomSimpleForm.defaultProps = {
  submitOnEnter: true,
  toolbar: <Toolbar />,
};

const enhance = compose(
  connect((state, props) => ({
    form: props.form || REDUX_FORM_NAME,
    initialValues: getDefaultValues(state, props),
    saving: props.saving || state.admin.saving,
  })),
  translate, // Must be before reduxForm so that it can be used in validation
  reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })
);

export default enhance(CustomSimpleForm);
