import React from 'react';
import {
  Show,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  Tab,
} from 'react-admin';

import CustomResourceList from 'common/custom-resource-list/CustomResourceList';

import {UsersFilter, UsersGrid, usersListProps, UsersIcon} from 'resources/users';
import {FarmsFilter, FarmsGrid, farmsListProps, FarmsIcon} from 'resources/farms';
import {WorkersFilter, WorkersGrid, workersListProps, WorkersIcon} from 'resources/workers';
import {WalletsFilter, WalletsGrid, walletsListProps, WalletsIcon} from 'resources/wallets';
import {UserEventsFilter, UserEventsGrid, userEventsListProps, UserEventsIcon} from 'resources/user_events';
import {FarmEventsFilter, FarmEventsGrid, farmEventsListProps, FarmEventsIcon} from "resources/farm_events";
import {CoinpaymentsFilter, CoinpaymentsGrid, coinpaymentsListProps, CoinpaymentsIcon} from 'resources/coinpayments';
import {TokensFilter, TokensGrid, tokensListProps, TokensIcon} from 'resources/tokens';
import {ApiKeysFilter, ApiKeysGrid, apiKeysListProps, ApiKeysIcon} from 'resources/api_keys';
import {AccessLogFilter, AccessLogGrid, accessLogListProps, AccessLogIcon} from 'resources/access_log';
import {TransactionsFilter, TransactionsGrid, transactionsListProps, TransactionsIcon} from 'resources/transactions';
import {AclFilter, AclGrid, aclListProps, AclIcon} from "resources/acl";
import {DepositWalletsFilter, DepositWalletsGrid, DepositWalletsIcon, depositWalletsListProps} from "resources/deposit_wallets";

import Title from 'components/users/Title';
import EditAttributes from 'components/users/EditAttributes';
import UserInfo from "./UserInfo";
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';

const UserEdit = ({permissions: p, ...props}) => (
  <Show title={<Title/>} actions={null} {...props}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant='scrollable'/>}>
      <Tab label='Info' icon={<InfoIcon/>}>
        <UserInfo permissions={p}/>
      </Tab>

      {p && p['admin.users.manage.*'] && <Tab label='Edit' path='edit' icon={<EditIcon/>}>
        <EditAttributes permissions={p} {...props}/>
      </Tab>}

      {p && p['admin.farms'] && <Tab label='Farms' path='farms' icon={<FarmsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-projects'
          {...props}
          {...farmsListProps}
          custom='farms'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<FarmsFilter/>}
          bulkActionButtons={false}
        >
          <FarmsGrid permissions={p}/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.acl'] && <Tab label='ACL' path='acl' icon={<AclIcon/>}>
        <CustomResourceList
          key='custom-resource-list-acl'
          {...props}
          {...aclListProps}
          custom='acl'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<AclFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <AclGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.workers'] && <Tab label='Workers' path='workers' icon={<WorkersIcon/>}>
        <CustomResourceList
          key='custom-resource-list-workers'
          {...props}
          {...workersListProps}
          custom='workers'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<WorkersFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <WorkersGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.wallets'] && <Tab label='Wallets' path='wallets' icon={<WalletsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-wallets'
          {...props}
          {...walletsListProps}
          custom='wallets'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<WalletsFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <WalletsGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.user_events'] && <Tab label='User ev' title='User events' path='user_events' icon={<UserEventsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-user-events'
          {...props}
          {...userEventsListProps}
          custom='user_events'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<UserEventsFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <UserEventsGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.farm_events'] && <Tab label='Farm ev' title='Farm events' path='farm_events' icon={<FarmEventsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-farm-events'
          {...props}
          {...farmEventsListProps}
          custom='farm_events'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<FarmEventsFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <FarmEventsGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.transactions'] && <Tab label='Trans' title='Transactions' path='transactions' icon={<TransactionsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-transactions'
          {...props}
          {...transactionsListProps}
          custom='transactions'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<TransactionsFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <TransactionsGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.coinpayments'] && <Tab label='Cnpmnts' title='Coinpayments' path='coinpayments' icon={<CoinpaymentsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-coinpayments'
          {...props}
          {...coinpaymentsListProps}
          custom='coinpayments'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<CoinpaymentsFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <CoinpaymentsGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.deposit_addresses'] && <Tab label='Dpst wal' title='Deposit wallets' path='deposit_addresses' icon={<DepositWalletsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-deposit_addresses'
          {...props}
          {...depositWalletsListProps}
          custom='deposit_addresses'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<DepositWalletsFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <DepositWalletsGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}

      <Tab label='Rf users' title='Referral users' path='ref_users' icon={<UsersIcon/>}>
        <CustomResourceList
          key='custom-resource-list-user-referrals'
          {...props}
          {...usersListProps}
          custom='users'
          customfilter={{target: 'referral_id', record: 'id'}}
          filters={<UsersFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <UsersGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>

      <Tab label='Rf farms' title='Referral farms' path='ref_farms' icon={<FarmsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-user-referral-farms'
          {...props}
          {...farmsListProps}
          custom='farms'
          customfilter={{target: 'referral_id', record: 'id'}}
          filters={<FarmsFilter/>}
          bulkActionButtons={false}
        >
          <FarmsGrid permissions={p}/>
        </CustomResourceList>
      </Tab>

      {p && p['admin.tokens'] && <Tab label='Tokens' path='tokens' icon={<TokensIcon/>}>
        <CustomResourceList
          key='custom-resource-list-tokens'
          {...props}
          {...tokensListProps}
          sort={{field: 'last_activity', order: 'DESC'}}
          custom='tokens'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<TokensFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <TokensGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.api_keys'] && <Tab label='API keys' path='api_keys' icon={<ApiKeysIcon/>}>
        <CustomResourceList
          key='custom-resource-list-api_keys'
          {...props}
          {...apiKeysListProps}
          custom='api_keys'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<ApiKeysFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <ApiKeysGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}

      {p && p['admin.accesslog'] && <Tab label='Acc log' title='Access log' path='access_log' icon={<AccessLogIcon/>}>
        <CustomResourceList
          key='custom-resource-list-tokens'
          {...props}
          {...accessLogListProps}
          custom='accesslog'
          customfilter={{target: 'user_id', record: 'id'}}
          filters={<AccessLogFilter consumer='users'/>}
          bulkActionButtons={false}
        >
          <AccessLogGrid permissions={p} consumer='users'/>
        </CustomResourceList>
      </Tab>}
    </TabbedShowLayout>
  </Show>
);

export default UserEdit;
