import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const ICON_WIDTH = 25;
const NAME_WIDTH = 140;
const VALUE_WIDTH = 85;
const LAST_VALUE_WIDTH = 125;
const MAX_DATA_LENGTH = 100;

class Table extends Component {
  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.array.isRequired,
    getColor: PropTypes.func,
    getIcon: PropTypes.func
  };

  static defaultProps = {
    getColor: () => '#bfbfbf',
    getIcon: () => null,
  }

  constructor(props) {
    super(props);

    this.expandable = (props.data.length > MAX_DATA_LENGTH);
    this.allData = props.data;
    this.data = this.sliceData(props.data, this.expandable);
    this.state = {
      expanded: false,
      data: this.data,
    }
  }

  sliceData(data, isExpandable) {
    if (isExpandable) {
      return data.slice(0, MAX_DATA_LENGTH);
    }

    return data;
  }

  getContainerWidth(data) {
    let columnsWidth = NAME_WIDTH;
    columnsWidth += this.getValueContainerWidth(data)

    if (this.props.getIcon() !== null) {
      columnsWidth += ICON_WIDTH;
    }

    return columnsWidth;
  }

  getValueContainerWidth(data) {
    let width = 0;

    if (data && data.length) {
      if (typeof data[0].value === 'string') {
        width += LAST_VALUE_WIDTH;
      } else if (Array.isArray(data[0].value)) {
        width += (data[0].value.length - 1) * VALUE_WIDTH + LAST_VALUE_WIDTH;
      }
    }

    return width;
  }

  putValue({ name, value }) {
    const { classes } = this.props;

    if (typeof value === 'string') {
      return (
        <span className={ classes.surroundings } style={ { backgroundColor: this.props.getColor(name) } }>
          { value }
        </span>
      );
    }

    if (Array.isArray(value)) {
      return (
        <div className={ classes.valueWrap }>
          {
            value.map((item, index) => {
              if (index === value.length - 1) {
                return (
                  <div className={ classes.valueLastItem } key={ index }>
                    <span className={ classes.surroundings } style={ { backgroundColor: this.props.getColor(name) } }>
                      { item }
                    </span>
                  </div>
                );
              }

              return (
                <div className={ classes.valueCommonItem } key={ index }>
                  <span className={ classes.valueItem }>{ item }</span>
                </div>
              );
            })
          }
        </div>
      );
    }

    return null;
  }

  handleClick() {
    this.setState({
      expanded: !this.state.expanded,
      data: this.state.expanded ? this.data : this.allData,
    })
  }

  putExpandButton() {
    if (!this.expandable) {
      return null;
    }

    return (
      <button
        className={ this.props.classes.more }
        onClick={ this.handleClick.bind(this) }
      >
        { this.state.expanded ? 'less' : 'more' }
      </button>
    );
  }

  render() {
    const { classes, data } = this.props;

    if (!data || !data.length) {
      return null;
    }

    return (
      <div className={ classes.main }>
        <h3 className={ classes.title }>{ this.props.title }</h3>
        <div className={ classes.container }
          style={{ gridTemplateColumns: 'repeat(auto-fit, ' + this.getContainerWidth(data) + 'px)' }}
        >
          { this.state.data.map((item, index) => {
            return (
              <div key={ index }>
                <div className={ classes.icon }>
                  { this.props.getIcon(item.name) }
                </div>
                <div className={ classes.keyContainer }>
                  <span className={ classes.key }>{ item.name }</span>
                </div>
                <div className={ classes.valueContainer } style={{ width: this.getValueContainerWidth(data) + 'px' }}>
                  { this.putValue(item) }
                </div>
              </div>
            );
          }) }
        </div>
        { this.putExpandButton() }
      </div>
    );
  }
}

const styles = theme => ({
  main: {
    width: '100%',
    padding: '25px 5px',
  },
  title: {
    textAlign: 'center',
  },
  container: {
    display: 'grid',
    gridGap: '2px 15px',
    justifyContent: 'center',
    clear: 'both',
  },
  icon: {
    float: 'left',
  },
  keyContainer: {
    float: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: NAME_WIDTH + 'px',
  },
  key: {
    padding: '2px 5px',
  },
  valueContainer: {
    float: 'right',
    textAlign: 'right',
  },
  valueWrap: {
    display: 'flex',
  },
  valueCommonItem: {
    width: VALUE_WIDTH + 'px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'right',
    padding: '2px 0',
  },
  valueItem: {
    padding: '0 10px',
  },
  valueLastItem: {
    width: LAST_VALUE_WIDTH + 'px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  surroundings: {
    whiteSpace: 'nowrap',
    borderRadius: '20px',
    padding: '2px 10px',
    lineHeight: '22px',
    color: theme.colors.layout,
  },
  more: {
    width: '100%',
    textDecoration: 'underline',
    textAlign: 'center',
    background: 'none',
    border: 'none',
    padding: '5px',
    color: theme.colors.text,
  },
});

export default withStyles(styles)(Table);
