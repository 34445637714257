import React from 'react';
import {
  Edit,
  TextField,
  BooleanInput,
  TextInput,
  LongTextInput,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';

import NoopComponent from 'common/noop-component/NoopComponent';
import Toolbar from 'common/toolbar-save-button/ToolbarSaveButton';
import AdaptiveLayout from 'common/layout/AdaptiveLayout';
import Enroll from 'common/enroll/Enroll';

import ResetLock from 'components/farms/ResetLock';
import CustomSimpleForm from "common/forms/CustomSimpleForm";
import {asyncValidateEdit} from "common/forms/Utils";
import {userOptionRenderer} from "fields/UserAliasField";
import Switch from "./rocketchain/Switch";
import {CONFIG} from "config";

const EditAttributes = ({permissions: p = {}, ...props}) => (
  <AdaptiveLayout>
    <Edit {...props} title={<NoopComponent/>}>
      <CustomSimpleForm toolbar={<Toolbar/>} asyncValidate={asyncValidateEdit}>
        <TextField source='id'/>
        {p['admin.farms.manage.owner'] && <ReferenceInput label="Owner" source="user_id" reference="users">
          <AutocompleteInput optionText={userOptionRenderer}/>
        </ReferenceInput>}
        {p['admin.farms.manage'] && <BooleanInput source='nonfree'/>}
        {p['admin.farms.manage'] && <TextInput source='discount'/>}
        {p['admin.farms.manage.comment'] && <LongTextInput source='admin_comment'/>}
      </CustomSimpleForm>
    </Edit>

    <Edit {...props} title={<NoopComponent/>}>
      <CustomSimpleForm toolbar={<NoopComponent/>}>
        {p['admin.farms.manage.unlock'] && <ResetLock noButton={false}/>}
        {p['admin.farms.manage.gift'] && <Enroll targeturl='farms'/>}
      </CustomSimpleForm>
    </Edit>
    {CONFIG.brand === 'rocketchain' && <Edit
      {...props}
      key='farms-rocketchain'
      title={<NoopComponent/>}
      className={props.classes.root}
    >
      <CustomSimpleForm toolbar={<NoopComponent/>}>
        <Switch/>
      </CustomSimpleForm>
    </Edit>}
  </AdaptiveLayout>
);

const styles = theme => ({
  root: {
    ['& div']: { // eslint-disable-line no-useless-computed-key
      boxShadow: 'none',
    }
  }
});

export default withStyles(styles)(EditAttributes);
