import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

class DonutTooltip extends Component {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.string,
  };

  render() {
    const { active, classes, payload, total } = this.props;

    if (!active) {
      return null;
    }

    return (
      <div className={ classes.main }>
        <span>{`${payload[0].name} : ${payload[0].value} (${(payload[0].value * 100 / total).toFixed(2)}%)`}</span>
      </div>
    );
  }
}

const styles = theme => ({
  main: {
    boxSizing: 'border-box',
    padding: '6px 12px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '2px',
    color: '#333',
  },
});

export default withStyles(styles)(DonutTooltip);
