import React from 'react';
import PropTypes from 'prop-types';

const UrlField = ({record = {}, source, hrefpattern = null, target = '_blank', title = ''}) => {
  let href = record[source];
  if (hrefpattern) {
    href = hrefpattern;
    Object.keys(record).forEach(key => {
      href = href.replace(`{${ key }}`, record[key]);
    });
  }

  return (
    <a href={ href } target={ target } title={ title }>
      { record[source] }
    </a>
  );
};

UrlField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

UrlField.defaultProps = {
  record: {},
  addLabel: true,
};

export default UrlField;
