import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  ChipField,
  ReferenceArrayField,
  ReferenceArrayInput,
} from 'react-admin'
import Icon from '@material-ui/icons/Security';
import {CustomPagination} from "../common/pagination/CustomPagination";
import SortedSingleFieldList from "../common/lists/SortedSingleFieldList";
import CustomCheckboxGroupInput from "../common/input/CustomCheckboxGroupInput";
import {asyncValidateEdit} from "../common/forms/Utils";

export const PermissionsIcon = Icon;

export const PermissionsFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Name' source='name' alwaysOn/>
  </Filter>
);

export const PermissionsGrid = (({consumer, classes: c, permissions, ...props}) => {
  return <Datagrid {...props}>
    <TextField source='id'/>
    <TextField source='name'/>
    <TextField source='title'/>
    <TextField source='description'/>
    <ReferenceArrayField label="Roles" reference="roles" source="role_ids" sortable={false}>
      <SortedSingleFieldList sortBy="id">
        <ChipField source="name"/>
      </SortedSingleFieldList>
    </ReferenceArrayField>
    <TextField source='sort_order'/>
    {permissions && permissions['admin.roles.manage'] && <EditButton/>}
  </Datagrid>
});

export const PermissionsList = ({permissions, ...props}) => (
  <List
    title="Roles"
    {...props}
    sort={{field: 'sort_order', order: 'ASC'}}
    pagination={<CustomPagination/>}
    perPage={50}
    filters={<PermissionsFilter/>}
    bulkActionButtons={false}
  >
    <PermissionsGrid permissions={permissions}/>
  </List>
);

const PermissionEditForm = (props) => (
  <SimpleForm {...props} asyncValidate={asyncValidateEdit}>
    <TextField source="name"/>
    <TextField source="title"/>
    <TextField source="description"/>
    <ReferenceArrayInput
      source="role_ids"
      reference="roles"
      label="Roles"
      sort={{field: 'name', order: 'ASC'}}
      perPage={10000}
    >
      <CustomCheckboxGroupInput optionText="display_name"/>
    </ReferenceArrayInput>
  </SimpleForm>
);

export const PermissionEdit = (props) => (
  <Edit {...props}>
    <PermissionEditForm/>
  </Edit>
);
