import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import cx from 'classnames';
import colors from 'data/colors.json';

const colorsNames = Object.keys(colors);
const SHADE = '400';

const TagField = ({record, source, classes: c, showColor, showName}) => {
  const name = record['name'];
  const colorId = record['color'];
  const color = colorsNames[colorId] || colorsNames[0];

  const style = {
    backgroundColor: colors[color][SHADE],
  };

  return (
    <span className={c.main}>
      {showColor ? <span className={c.color} style={style}></span> : ''}
      {showName ? <span className={c.name}>{name}</span> : ''}
    </span>
  );
};

TagField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string, // not required

  showColor: PropTypes.bool,
  showName: PropTypes.bool,
};

TagField.defaultProps = {
  record: {},
  addLabel: true,

  showColor: true,
  showName: true,
};

const styles = theme => ({
  main: {
    display: 'flex',
  },
  color: {
    display: 'inline-block',
    width: 17,
    height: 17,
    marginRight: 5,
    borderRadius: '50%',
  },
  name: {}
});

const EnhancedTagField = withStyles(styles)(TagField);

EnhancedTagField.defaultProps = {
  addLabel: true,
};

export default EnhancedTagField;
