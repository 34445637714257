import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

const ReferralBalance = (props) => {
  const {record, source, classes: c} = props;
  const balances = record[source];
  if (!balances) {
    return null;
  }
  return <div className={ c.main }>
    { Object.getOwnPropertyNames(balances).map(currency => {
      const balance = balances[currency];
      return <div className={ c.row } key={ currency }>
        <span className={ c.currency }>{ currency }</span>
        <span className={ c.balance }>{ balance }</span>
      </div>;
    }) }
  </div>
};

ReferralBalance.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

ReferralBalance.defaultProps = {
  record: {},
};

const styles = theme => ({
	main: {
		margin: '5px 0',
	},
	row: {
		display: 'flex',
	},
	currency: {
		fontWeight: 'bold',
		minWidth: '50px',
	},
	balance: {

	},
});

export default withStyles(styles)(ReferralBalance);
