import TagField from "../../fields/TagField";
import {ChipField} from "ra-ui-materialui";
import React from "react";

export const TagChip = ({record, ...props}) => {
  const rec = {
    __chip: <TagField record={record}/>,
    ...record
  };
  return <ChipField source="__chip" record={rec} {...props}/>;
};
