import React from 'react';
import {SingleFieldList} from "ra-ui-materialui";
import _ from "lodash";
import PropTypes from 'prop-types';

class SortedSingleFieldList extends React.Component {
  render() {
    let {ids, sortBy, sortOrder, ...props} = this.props;
    if (sortBy) {
      const records = ids.map(id => props.data[id]).filter(record => !!record);
      // collect new sorted array of ids
      ids = _.sortBy(records, sortBy).map(record => record.id);
      if (/^desc$/i.test(sortOrder)) {
        ids = ids.reverse();
      }
    }
    return <SingleFieldList ids={ids} {...props}/>
  }
}

SortedSingleFieldList.propTypes = {
  sortBy: PropTypes.string, // field name or getter function
  sortOrder: PropTypes.string, // asc or desc
};

export default SortedSingleFieldList;
