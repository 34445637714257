import React from 'react';
import PropTypes from 'prop-types';
import cx from "classnames";
import {withStyles} from '@material-ui/core/styles';
import {ChipField} from 'react-admin';

const TransactionStatus = (props) => {
  const {record, source, threshold, classes: c, ...restProps} = props;
  const className = record[source];
  if (!className) {
    return null;
  }
  return <ChipField
    {...restProps}
    record={record}
    source={source}
    className={cx(c[className])}
  />
};

TransactionStatus.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

TransactionStatus.defaultProps = {};

const styles = theme => ({
  success: {
    color: '#86ff86',
  },
  pending: {
    color: '#c5c5c5',
  },
  dropped: {
    color: '#ff8f96',
  },
  error: {
    color: '#ff8f96',
  }
});

export default withStyles(styles)(TransactionStatus);
