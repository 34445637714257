import React from 'react';
import PropTypes from 'prop-types';
import { fetchApi } from 'providers/myRestProvider';
import {fetchEnd, fetchStart, showNotification} from 'react-admin';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';

class ConvertToEthButton extends React.Component {
	static propTypes = {
		done: PropTypes.func,
		record: PropTypes.object,
	};

	handleClick = () => {
		const {record, done, showNotification, fetchStart, fetchEnd, selectedIds} = this.props;
		let url, data;
		if (selectedIds) {
			// multi mode
			url = `referrals/convert_to_eth`;
			data = {ids: selectedIds};
		}
		else {
			url = `referrals/${record.id}/convert_to_eth`;
		}
		fetchStart();
		fetchApi(url, {method: 'POST', body: data})
			.then(() => {
				showNotification('Balance converted');
				if (done) done();
			})
			.catch((e) => {
				showNotification(e.message, 'warning')
			})
			.finally(fetchEnd);
	};

	render() {
		return <Button color="primary" variant="contained" onClick={this.handleClick}>
			Convert to ETH
		</Button>;
	}
}

export default connect(null, {
	showNotification, fetchStart, fetchEnd
})(ConvertToEthButton);
