import React from 'react';
import dataProvider, {GET} from 'providers/myRestProvider';
import {connect} from 'react-redux';
import {showNotification} from 'react-admin';

import GeneralInfo from 'components/totalstats/GeneralInfo';

class TotalStatsSummary extends React.Component {
  state = {
    stats: null,
  };

  componentDidMount() {
    const {showNotification} = this.props;

    dataProvider(GET, 'total_stats').then(response => {
      this.setState({stats: response})
    }).catch((e) => {
      if (e.status === 401 || e.status === 403) {
        return;
      }
      console.error(e);
      showNotification('Could not load data', 'warning')
    });
  }

  getData() {
    const {stats} = this.state;
    if (!stats) {
      return null;
    }

    return [
      {key: 'Users', value: stats.users || 0, href: '/users'},
      {key: 'Farms', value: stats.projects || 0, href: '/farms'},
      {key: 'Workers', value: stats.workers || 0, href: '/workers'},
      {key: 'GPUs', value: stats.gpus || 0, href: '/total_stats'},
      {key: 'CPUs', value: stats.cpus || 0, href: '/total_stats'},
      {key: 'ASICs', value: stats.asics || 0, href: '/total_stats'},
    ];
  }

  render() {
    const data = this.getData();
    if (!data) {
      return null;
    }
    return (
      <GeneralInfo
        data={data}
        variant='cards'
      />
    );
  }
}

export default connect(null, {
  showNotification,
})(TotalStatsSummary);
