import React from 'react';
import PropTypes from 'prop-types';
import AliasField from "./AliasField";

const TokenAliasField = ({showId, showName, record, ...restProps}) => {
  if (!record) {
    return null;
  }

  const {id, personal, name} = record;
  let displayName;
  if (personal) {
    displayName = name || '[personal]';
  }
  else {
    displayName = '[session]';
  }

  return <AliasField
    nameField="name"
    showId={showId}
    record={{id, name: displayName}}
    {...restProps}
  />;
};

TokenAliasField.propTypes = {
  record: PropTypes.object,

  showId: PropTypes.bool,
};

TokenAliasField.defaultProps = {
  record: {},
  label: 'Token',
  addLabel: true,

  showId: true,
};

export default TokenAliasField;
