import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';

import TotalStatsSummary from "components/dashboard/TotalStatsSummary";

const Dashboard = ({permissions}) => (
  <Card>
    <CardHeader title='Dashboard'/>
    <CardContent>
      {permissions && permissions['admin.total_stats'] && <TotalStatsSummary/>}
    </CardContent>
  </Card>
);

const styles = theme => ({

});

export default withStyles(styles)(Dashboard);
