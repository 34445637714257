import React from 'react';
import {refreshView, setListSelectedIds} from 'react-admin';
import {connect} from 'react-redux';
import PayoutButton from "./PayoutButton";

class PayoutBulkButton extends React.Component {
	handleDone = () => {
		const {refreshView, setListSelectedIds, resource} = this.props;
		refreshView();
		setListSelectedIds(resource, []);
	};

	render() {
		const props = this.props;
		return <PayoutButton done={this.handleDone} {...props}/>
	}
}

export default connect(null, {
	refreshView, setListSelectedIds
})(PayoutBulkButton);
