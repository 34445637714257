import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Create,
  Edit,
  SimpleForm,
  EditButton,
  DeleteButton,
  ReferenceArrayInput,
  ReferenceArrayField,
  ChipField,
  Toolbar,
  SaveButton,
  LongTextInput,
} from 'react-admin'
import Icon from '@material-ui/icons/Security';
import {CustomPagination} from "../common/pagination/CustomPagination";
import SortedSingleFieldList from "../common/lists/SortedSingleFieldList";
import CustomCheckboxGroupInput from "../common/input/CustomCheckboxGroupInput";
import {makeAsyncValidate} from "../common/forms/Utils";
import UserChip from "../components/users/UserChip";
import PermissionChip from "../components/permissions/PermissionChip";

export const RolesIcon = Icon;

export const RolesFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Name' source='name' alwaysOn/>
  </Filter>
);

export const RolesGrid = ({consumer, classes: c, permissions, ...props}) => {
  return <Datagrid {...props}>
    <TextField source='id'/>
    <TextField source='name'/>
    <TextField source='description'/>
    <ReferenceArrayField label="Permissions" reference="permissions" source="permission_ids" sortable={false}>
      <SortedSingleFieldList sortBy="sort_order">
        <PermissionChip/>
      </SortedSingleFieldList>
    </ReferenceArrayField>
    <ReferenceArrayField label="Users" reference="users" source="user_ids" sortable={false}>
      <SortedSingleFieldList sortBy="id">
        <UserChip/>
      </SortedSingleFieldList>
    </ReferenceArrayField>
    {permissions && permissions['admin.roles.manage'] && <EditButton/>}
    {permissions && permissions['admin.roles.manage'] && <DeleteButton/>}
  </Datagrid>
};

export const RolesList = ({permissions, ...props}) => {
  return <List
    title="Roles"
    {...props}
    sort={{field: 'name', order: 'ASC'}}
    pagination={<CustomPagination/>}
    perPage={50}
    filters={<RolesFilter/>}
    bulkActionButtons={false}
  >
    <RolesGrid permissions={permissions}/>
  </List>
};

const PermissionOptionLabel = ({record}) => {
  const delimiterStyle = {
    margin: '0 10px',
  };
  const descStyle = {
    fontStyle: 'italic',
  };
  return <span>
    <span>{record.display_name}</span>
    {record.description && <span>
      <span style={delimiterStyle}>&bull;</span>
      <span style={descStyle}>{record.description}</span>
    </span>}
  </span>
};

const RoleForm = ({action, ...props}) => (
  <SimpleForm {...props} asyncValidate={makeAsyncValidate(action)}>
    <TextInput source="name"/>
    <LongTextInput source="description"/>
    <ReferenceArrayInput
      source="permission_ids"
      reference="permissions"
      label="Permissions"
      sort={{field: 'sort_order', order: 'ASC'}}
      perPage={10000}
    >
      <CustomCheckboxGroupInput optionText={<PermissionOptionLabel/>}/>
    </ReferenceArrayInput>
  </SimpleForm>
);

const RoleCreateToolbar = props => (
  <Toolbar {...props} >
    <SaveButton
      label="Save"
      redirect="list"
      submitOnEnter={true}
    />
    <SaveButton
      label="Save and add new"
      redirect={false}
      submitOnEnter={false}
      variant="flat"
    />
  </Toolbar>
);

export const RoleCreate = (props) => (
  <Create {...props}>
    <RoleForm toolbar={<RoleCreateToolbar/>} action='create'/>
  </Create>
);

const RoleEditTitle = ({record}) => {
  return <span>Edit Role {record ? `"${record.name}"` : ''}</span>;
};

export const RoleEdit = (props) => (
  <Edit {...props} title={<RoleEditTitle/>} action='edit'>
    <RoleForm/>
  </Edit>
);
