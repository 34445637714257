import React from "react";
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import {load, dump} from 'js-yaml'

class YamlField extends React.Component {
    static propTypes = {
        value: PropTypes.object,
        onChanged: PropTypes.func.isRequired,
        label: PropTypes.string,
        name: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        value: {},
        disabled: false,
    };

    state = {
        raw: '',
        isError: false
    };

    componentDidMount() {
        const {value} = this.props;
        this.setState({raw: value === null ? '' : dump(value)})
    }

    handleChanged = (e) => {
        const {value} = e.target;
        this.setState({raw: value});
        try {
            this.props.onChanged(load(value));
            this.setState({isError: false});
        } catch (e) {
            this.setState({isError: true});
        }
    }

    render() {
        return <TextField
            multiline
            disabled={this.props.disabled}
            label={this.props.label}
            key={this.props.name}
            name={this.props.name}
            value={this.state.raw || ''}
            type='text'
            fullWidth={true}
            onChange={this.handleChanged}
            margin="normal"
            error={this.state.isError || false}
        />
    }
}

export default YamlField;