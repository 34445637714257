import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
} from 'react-admin';
import ConfigsView from "./ConfigsView";
import StatsView from "./StatsView";
import CustomResourceList from "common/custom-resource-list/CustomResourceList";
import {FarmEventsFilter, FarmEventsGrid, farmEventsListProps, FarmEventsIcon} from "resources/farm_events";
import WorkerInfo from "./WorkerInfo";
import InfoIcon from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import EqualizerIcon from '@material-ui/icons/Equalizer';

export const WorkerShow = ({permissions: p, ...props}) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label='Info' icon={<InfoIcon/>}>
        <WorkerInfo permissions={p}/>
      </Tab>

      {p && p['admin.farm_events'] && <Tab label='Events' path='farm_events' icon={<FarmEventsIcon/>}>
        <CustomResourceList
          key='custom-resource-list-farm-events'
          {...props}
          {...farmEventsListProps}
          custom='farm_events'
          customfilter={{target: 'worker_id', record: 'id'}}
          filters={<FarmEventsFilter consumer='workers'/>}
          bulkActionButtons={false}
        >
          <FarmEventsGrid permissions={p} consumer='workers'/>
        </CustomResourceList>
      </Tab>}

      <Tab label='stats' path='stats' icon={<EqualizerIcon/>}>
        <StatsView/>
      </Tab>

      <Tab label='configs' path='configs' icon={<SettingsIcon/>}>
        <ConfigsView permissions={p} source='configs'/>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
