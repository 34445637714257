import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  ReferenceField,
} from 'react-admin'
import JsonField from 'fields/JsonField';
import Icon from '@material-ui/icons/CreditCard';

import {CustomPagination} from "../common/pagination/CustomPagination";
import LongTextField from "fields/LongTextField";
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import ApiKeyAliasField from "fields/ApiKeyAliasField";

export const WalletsIcon = Icon;

export const WalletsFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    <TextInput label='ID' source='id' alwaysOn/>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    {consumer !== 'farms' && <TextInput label='Farm ID' source='farm_id' alwaysOn/>}
    <TextInput label='Wal' source='wal' alwaysOn/>
    <TextInput label='API Key ID' source='api_key_id' alwaysOn/>
  </Filter>
);

export const WalletsGrid = (({consumer, permissions: p, classes: c, ...props}) => {
  return <Datagrid {...props}>
    <TextField source='id'/>
    <TextField source='coin'/>
    <TextField source='name'/>
    <LongTextField source='wal'/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
      <FarmAliasField/>
    </ReferenceField>}
    <ReferenceField label='API Key' source='api_key_id' reference='api_keys' allowEmpty={true} linkType='show'>
      <ApiKeyAliasField/>
    </ReferenceField>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <JsonField source='props' sortable={false}/>
  </Datagrid>
});

export const walletsListProps = {
  sort: {field: 'id', order: 'ASC'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

const WalletsList = ({permissions, ...props}) => (
  <List
    title='Wallets'
    {...props}
    {...walletsListProps}
    filters={<WalletsFilter/>}
    bulkActionButtons={false}
  >
    <WalletsGrid permissions={permissions}/>
  </List>
);

export default WalletsList;
