import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import { withStyles } from '@material-ui/core/styles';

import DonutTooltip from './DonutTooltip';
import DonutLegend from './DonutLegend';
import DonutTable from './DonutTable';

const RADIAN = Math.PI / 180;

class Donut extends Component {
  constructor(props) {
    super(props);

    this.total = this.getTotal(props.data);
    this.data = this.sliceTinyValues(props.data, this.total);
    this.allData = props.data;
  }

  getTotal(data) {
    return data.reduce((acc, current) => acc + current.value, 0)
  }

  sliceTinyValues(data, total) {
    let sliced = data.reduce((acc, current) => {
      if ((current.value > total * 0.02) // gather 2%
        && (acc.length < 15) // less than 15 items in array
      ) {
        acc.push(current)
      }
      return acc;
    }, []);

    let totalInSliced = this.getTotal(sliced);

    if (total > totalInSliced) {
      sliced.push({ name: 'other', value: total - totalInSliced});
    }

    return sliced;
  }

  getColor(entry, index) {
    if (typeof this.props.getColor === 'function') {
      return this.props.getColor(entry.name);
    }

    return '#eee';
  }

  putLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) {
   	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x  = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy  + radius * Math.sin(-midAngle * RADIAN);
    const value = (percent * 100).toFixed(0);

    if (value < 2) {
      return null;
    }

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={ x > cx ? 'start' : 'end' }
        dominantBaseline='middle'
        fontWeight='bold'
        fontSize='14px'
      >
        {`${value}%`}
      </text>
    );
  };

  render() {
    const classes = this.props.classes;

    return (
      <div className={ classes.main }>
        <h3>{ this.props.title }</h3>
        <DonutLegend
          data={ this.data }
          getColor={ this.props.getColor }
        />
        <PieChart width={ 300 } height={ 300 } className={ classes.pieChart }>
          <Pie
            dataKey='value'
            data={ this.data }
            innerRadius={ 45 }
            outerRadius={ 140 }
            paddingAngle={ 0 }
            labelLine={ false }
            label={ this.putLabel }
            isAnimationActive={ false }
            stroke="none"
          >
            { this.data.map((entry, index) => {
              return(<Cell key={ index } fill={ this.getColor(entry, index) }/>);
            }) }
          </Pie>
          <Tooltip content={ <DonutTooltip total={ this.total }/> }/>
        </PieChart>
        <DonutTable
          data={ this.data }
          allData={ this.allData }
          total={ this.total }
          getColor={ this.props.getColor }
        />
      </div>
    );
  }
}

const styles = theme => ({
  main: {
    flex: 1,
    padding: '0 5px',
    textAlign: 'center'
  },
  pieChart: {
    margin: '0 auto'
  },
});

Donut.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  getColor: PropTypes.func
};

export default withStyles(styles)(Donut);
