import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AttributeToggle from 'common/attribute-toggle/AttributeToggle';

export default class ResetLock extends Component {
  static propTypes = {
    noButton: PropTypes.bool,
  }

  static defaultProps = {
    noButton: true,
  }

  render() {
    const { props } = this;

    return (
      <AttributeToggle
        recordAttribute='is_locked'
        requestAttribute='unlock'
        labelText='Is Locked'
        enableText=''
        disableText='Unlock'
        disableValue={ true }
        noButton={ props.noButton }
        editOnTurnedOff={ false }
        record={ props.record }
        resource={ props.resource }
        translate={ props.translate }
      />
    );
  }
}
