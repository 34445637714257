import React from "react";
import _ from 'lodash';
import YesNoField from "fields/YesNoField";

const MoneyOverdraftField = ({record, source, showComment}) => {
  const money = _.get(record, source, {});
  const comment = money.has_overdraft && `${money.overdraft_days_left} days left` || '';
  return <YesNoField source='has_overdraft'
                     record={money}
                     comment={comment}
                     showComment={showComment}/>
};

MoneyOverdraftField.defaultProps = {
  showComment: true,

  source: 'money',
  label: 'Has overdraft',
  addLabel: true,
};

export default MoneyOverdraftField;