import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import {crudCreate, startUndoable} from 'ra-core';
import {CONFIG} from "config";

class Enroll extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
    startUndoable: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      comment: '',
      adminComment: '',
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleEnrollClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const {record, resource} = this.props;
    const {startUndoable} = this.props;

    const requestData = {
      amount: this.state.amount,
      comment: this.state.comment,
      admin_comment: this.state.adminComment,
    };
    const url = `${resource}/${record.id}/gift_of_fate`;
    startUndoable(crudCreate(url, requestData, '', false));
  };

  handleBalanceResetClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const {record} = this.props;

    const balance = +record.balance;
    this.setState({
      amount: !isNaN(balance) ? -balance : 0
    });
  };

  render() {
    const {record, classes: c} = this.props;
    const balance = +record.balance;

    return (
      <FormControl className={c.main}>
        <div>
          <TextField
            label={`Gift amount (${CONFIG.fiatCurrency})`}
            type='number'
            className={c.amount}
            value={this.state.amount}
            name='amount'
            onChange={this.handleInputChange}
          />
          <a className={c.balanceBtn} onClick={this.handleBalanceResetClick}>
            Balance reset ({balance}$)
          </a>
        </div>
        <div>
          <TextField
            label='Comment (visible to user)'
            type='text'
            className={c.comment}
            value={this.state.comment}
            name='comment'
            onChange={this.handleInputChange}
          />
        </div>
        <div>
          <TextField
            label='Admin comment (not visible to user)'
            type='text'
            className={c.comment}
            value={this.state.adminComment}
            name='adminComment'
            onChange={this.handleInputChange}
          />
        </div>
        <div>
          <Button color='primary' variant='contained' onClick={this.handleEnrollClick}>Enroll</Button>
        </div>
      </FormControl>
    );
  }
}

const styles = theme => ({
  main: {
    padding: '15px 0',
  },
  amount: {
    margin: '0 25px 0 0',
  },
  comment: {
    margin: '0 25px 10px 0',
    width: 400,
  },
  balanceBtn: {
    cursor: "pointer"
  }
});

export default connect(null, {
  startUndoable,
})(withStyles(styles)(Enroll));
