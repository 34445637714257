import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const PAGE_SIZE = 2;
const LEFT_DIRECTION = 'LEFT';
const RIGHT_DIRECTION = 'RIGHT';

class DonutLegend extends Component {
  constructor(props) {
    super(props);

    this.totalPages = Math.round(props.data.length / PAGE_SIZE);

    this.state = {
      currentPage: 0
    };
  }

  putPage() {
    let pageItems = [];

    for (let ii = this.state.currentPage * PAGE_SIZE;
      ((ii < (this.state.currentPage  * PAGE_SIZE) + PAGE_SIZE)
      && ii < this.props.data.length);
      ii++
    ) {
      let name = this.props.data[ii].name;

      pageItems.push(<span key={ ii } className={ this.props.classes.pageItemContainer }>
        <span className={ this.props.classes.pageItemColor }
          style={ { backgroundColor: this.props.getColor(name) } }
        >&nbsp;</span>
        <span className={ this.props.classes.pageItem }>
          { name }
        </span>
      </span>);
    }

    /* empty second span if not enough data */
    if (pageItems.length === 1) {
      pageItems.push(<span key='2' className={ this.props.classes.pageItemContainer }></span>);
    }

    return pageItems;
  }

  handleArrowClick(dir) {
    if ((dir === LEFT_DIRECTION) && (this.state.currentPage > 0)) {
      this.setState({
        currentPage: this.state.currentPage - 1
      })
    }

    if ((dir === RIGHT_DIRECTION) && (this.state.currentPage < this.totalPages - 1)) {
      this.setState({
        currentPage: this.state.currentPage + 1
      })
    }
  }

  putPagination() {
    if (this.totalPages < 2) {
      return null;
    }

    return (
      <span className={ this.props.classes.paginator }>
        <span className={ this.props.classes.arrow } onClick={ this.handleArrowClick.bind(this, LEFT_DIRECTION)}>
          &#9668;
        </span>
        <span>{ (this.state.currentPage + 1) + '/' + this.totalPages }</span>
        <span className={ this.props.classes.arrow }onClick={ this.handleArrowClick.bind(this, RIGHT_DIRECTION)}>
          &#9658;
        </span>
      </span>
    );
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={ classes.main }>
        { this.putPage() }
        { this.putPagination() }
      </div>
    );
  }
}

const styles = theme => ({
  main: {
    padding: '10px',
    width: '400px',
    display: 'flex',
    margin: '0 auto',
    textAlign: 'left',
    overflowX: 'hidden',
  },
  paginator: {
    userSelect: 'none',
    padding: '5px 10px',
    flex: 1,
  },
  pageItemContainer: {
    padding: '5px 10px',
    flex: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  pageItemColor: {
    padding: '0 6px',
    borderRadius: '10px'
  },
  pageItem: {
    padding: '0 10px',
  },
  arrow: {
    color: '#ccc',
    padding: '5px 10px',
    cursor: 'pointer'
  }
});

DonutLegend.propTypes = {
  data: PropTypes.array.isRequired,
  getColor: PropTypes.func
};

export default withStyles(styles)(DonutLegend);
