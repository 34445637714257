import React from "react";
import {
  DateField,
  ReferenceArrayField,
  ReferenceField,
  SimpleShowLayout,
  TextField
} from "react-admin";
import AdaptiveLayout from "common/layout/AdaptiveLayout";
import CustomSelectField from "fields/CustomSelectField";
import FarmAliasField from "fields/FarmAliasField";
import UserAliasField from "fields/UserAliasField";
import SortedSingleFieldList from "common/lists/SortedSingleFieldList";
import {TagChip} from "components/tags/TagChip";
import JsonField from "fields/JsonField";
import {PLATFORMS} from "constants/types";
import WorkerAliasField from "fields/WorkerAliasField";
import BillingDataField from "./BillingDataField";
import IsOnlineField from "./IsOnlineField";

const platforms = Object.keys(PLATFORMS).map(id => ({id: +id, name: PLATFORMS[id]}));

const WorkerInfo = (props) => (
  <div>
    <AdaptiveLayout>
      <SimpleShowLayout {...props}>
        <TextField source="id"/>
        <WorkerAliasField link={true} label='Name'/>
        <CustomSelectField source='platform' choices={platforms}/>
        <ReferenceField label='Farm' source='farm_id' reference='farms'>
          <FarmAliasField showId={true}/>
        </ReferenceField>
        <ReferenceField label='User' source='user_id' reference='users'>
          <UserAliasField showId={true} showName={true}/>
        </ReferenceField>
        <ReferenceField label='Referral' source='referral_id' reference='users' allowEmpty={true}>
          <UserAliasField showId={true} showName={true}/>
        </ReferenceField>
        <ReferenceArrayField label='Tags' reference='tags' source='tag_ids' sortable={false} allowEmpty={true}>
          <SortedSingleFieldList sortBy='id' linkType='show'>
            <TagChip/>
          </SortedSingleFieldList>
        </ReferenceArrayField>
        <DateField source='created_at' showTime locales='uk-UA'/>
      </SimpleShowLayout>

      <SimpleShowLayout {...props}>
        <IsOnlineField showComment={true}/>
        <BillingDataField source='billing_data'/>
      </SimpleShowLayout>

      <SimpleShowLayout {...props}>
        <JsonField source='props' collapsed={false} buttons={false}/>
      </SimpleShowLayout>
    </AdaptiveLayout>
  </div>
);

export default WorkerInfo;
