import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

class CoinIcon extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number
  };

  static defaultProps = {
    width: 14,
    height: 14,
  }

  constructor(props) {
    super (props);

    this.state = {
      exist: null,
    }
  }

  componentDidMount() {
    let name = this.props.name.toString().toLowerCase();
    this.image = new Image();

    this.image.onload = () => {
      this.setState({
        exist: true,
      });
    }

    this.image.onerror = () => {
      this.setState({
        exist: false,
      });
    }

    setTimeout(() => { this.image.src = `/coins/${name}.png`; },
      Math.floor(Math.random() * (500 + 1))
    );
  }

  render() {
    let style = {
      width: this.props.width,
      height: this.props.height,
    }

    if (this.state.exist === null) {
      return <span className={ this.props.classes.defaultIcon } style={ style }></span>;
    }

    if (this.state.exist === true) {
      style.backgroundImage = 'url(' + this.image.src + ')';

      return <span><div className={ this.props.classes.icon } style={ style }/></span>;
    }

    if (this.state.exist === false) {
      return <span className={ this.props.classes.defaultIcon } style={ style }>?</span>;
    }

    return null;
  };
}

const styles = theme => ({
  icon: {
    padding: '3px 2px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  defaultIcon: {
    padding: '0px 4px',
    background: '#eee',
    color: '#666',
    fontWeight: 'bold',
    borderRadius: '10px',
  }
});

export default withStyles(styles)(CoinIcon);
