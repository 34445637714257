import _ from 'lodash';
import myRestProvider, {VALIDATE_FOR_CREATE, VALIDATE_FOR_UPDATE} from "providers/myRestProvider";

/**
 * Extracts only edited fields from record
 * @param {object} values Edited object (typically is the whole record)
 * @param {object} props Form component props
 * @return {object} New object containing only keys from input that are edited in form
 */
export function extractFormValues(values, {registeredFields}) {
  return _.pick(values, Object.keys(registeredFields));
}

export function makeAsyncValidate(action) {
  return (values, dispatch, props, blurredField) => _asyncValidate(action, values, dispatch, props, blurredField);
}

export const asyncValidateEdit = makeAsyncValidate('edit');

/**
 * This function performs server-side validation
 * @param {string} action 'create' or 'edit'
 * @param {object} values Edited object
 * @param {Function} dispatch Redux dispatch function
 * @param {object} props Form component props
 * @param {string} blurredField Current blurred field
 * @return {Promise}
 */
function _asyncValidate(action, values, dispatch, props, blurredField) {
  if (blurredField !== undefined) {
    // skip per-field validation
    return Promise.resolve();
  }

  //console.log('asyncValidate', data, props);

  const {resource, record, initialValues} = props;
  const params = {
    id: record.id,
    data: extractFormValues(values, props),
    previousData: initialValues
  };

  const request = action === 'create' ? VALIDATE_FOR_CREATE : VALIDATE_FOR_UPDATE;
  return myRestProvider(request, resource, params)
    .catch((/* HttpError */ e) => {
      //console.log('asyncValidate fail', e, e.data);
      const {errors} = e.data || {};
      return Promise.reject(errors);
    });
}
