import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import DonutTableRow from "./DonutTableRow";

class DonutTable extends Component {
  static propTypes = {
    total: PropTypes.number,
    data: PropTypes.array.isRequired,
    allData: PropTypes.array.isRequired,
    getColor: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.expandable = (props.data.length < props.allData.length);
    this.state = {
      expanded: false,
      data: props.data,
    }
  }

  handleClick() {
    this.setState({
      expanded: !this.state.expanded,
      data: this.state.expanded ? this.props.data : this.props.allData,
    })
  }

  putExpandButton() {
    if (!this.expandable) {
      return null;
    }

    return (
      <button
        className={this.props.classes.more}
        onClick={this.handleClick.bind(this)}
      >
        {this.state.expanded ? 'less' : 'more'}
      </button>
    );
  }

  render() {
    let {total} = this.props;
    const {classes} = this.props;

    if (!total) {
      total = this.props.allData.reduce((acc, item) => acc + item.value, 0);
    }

    return <div className={classes.main}>
      {this.state.data.map((item, index) => {
        if (!item.name || !item.value) {
          return null;
        }

        return <DonutTableRow item={item} total={total} key={index}/>;
      })}
      {this.putExpandButton()}
    </div>;
  }
}

const styles = theme => ({
  main: {
    padding: '10px',
    maxWidth: '400px',
    margin: '0 auto',
  },
  more: {
    width: '100%',
    textDecoration: 'underline',
    textAlign: 'center',
    background: 'none',
    border: 'none',
    padding: '5px',
    color: theme.colors.text,
    cursor: 'pointer',
  }
});

export default withStyles(styles)(DonutTable);
