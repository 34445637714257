import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  SelectInput,
  ReferenceField
} from 'react-admin';
import JsonField from 'fields/JsonField';
import Icon from '@material-ui/icons/Event';
import {EVENT_TYPES, FARM_EVENT_TYPES} from '../constants/types';
import {CustomPagination} from "common/pagination/CustomPagination";
import CustomSelectField from "fields/CustomSelectField";
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import IPField from "fields/IPField";
import WorkerAliasField from "fields/WorkerAliasField";

const allTypeIds = Object.keys(EVENT_TYPES).map(id => ({id: +id, name: EVENT_TYPES[id]}));
const farmTypeIds = Object.keys(FARM_EVENT_TYPES).map(id => ({id: id, name: FARM_EVENT_TYPES[id]}));

export const FarmEventsIcon = Icon;

export const FarmEventsFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    {consumer !== 'farms' && <TextInput label='Farm ID' source='farm_id' alwaysOn/>}
    {consumer !== 'workers' && <TextInput label='Worker ID' source='worker_id' alwaysOn/>}
    <SelectInput label='Type ID' source='type_id' choices={farmTypeIds} alwaysOn/>
    <TextInput label='IP address' source='ip' alwaysOn/>
    <TextInput source='props' alwaysOn/>
  </Filter>
);

export const FarmEventsGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <CustomSelectField source='type_id' choices={allTypeIds}/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
      <FarmAliasField/>
    </ReferenceField>}
    {consumer !== 'workers' && <ReferenceField label='Worker' source='worker_id' reference='workers' allowEmpty={true} linkType='show'>
      <WorkerAliasField/>
    </ReferenceField>}
    <IPField source='ip'/>
    <TextField source='hostname'/>
    <JsonField source='props' sortable={false}/>
    <ReferenceField label='Admin' source='props.admin_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>
  </Datagrid>
);

export const farmEventsListProps = {
  sort: {field: 'id', order: 'desc'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

const FarmEventsList = ({permissions, ...props}) => (
  <List
    title='Farm events'
    {...props}
    {...farmEventsListProps}
    filters={<FarmEventsFilter/>}
    bulkActionButtons={false}
  >
    <FarmEventsGrid permissions={permissions}/>
  </List>
);

export default FarmEventsList;
