import React from 'react';
import PropTypes from 'prop-types';
import AliasField from "./AliasField";

const ApiKeyAliasField = ({showId, showName, ...restProps}) => {
  return <AliasField
    nameField='name'
    detailsField='source_name'
    showId={showId}
    showDetails={showName}
    {...restProps}
  />;
};

ApiKeyAliasField.propTypes = {
  record: PropTypes.object,

  showId: PropTypes.bool,
  showName: PropTypes.bool,
};

ApiKeyAliasField.defaultProps = {
  record: {},
  label: 'User',
  addLabel: true,

  showId: false,
  showName: false,
};

export default ApiKeyAliasField;
