import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import dataProvider, {GET} from "../providers/myRestProvider";
import Edit from "../components/settings/Edit";

export {default as SettingsIcon} from '@material-ui/icons/SettingsApplications';

class Settings extends React.Component {
  state = {
    isFetching: true,
    settings: []
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.version !== prevProps.version) {
      this.loadData();
    }
  }

  loadData() {
    dataProvider(GET, 'settings')
      .then(response => {
        this.setState({isFetching: false, settings: response.settings})
      });
  }

  render() {
    const {permissions} = this.props;
    const {isFetching, settings} = this.state;
    return <Card>
      <CardHeader title="Settings"/>
      <CardContent>

        {isFetching && <div>loading</div>}

        {!isFetching && <Edit {...{settings, permissions}}/>}

          </CardContent>
    </Card>
  }
}

export default Settings;
