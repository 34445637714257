import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {TextField} from 'react-admin';
import UrlField from "./UrlField";
import {getExploreUrl} from "utils/blockchain";

const BlockchainUrlField = ({source, currencySource, modeSource, mode, label, record, ...props}) => {
  if (!mode && modeSource) {
    mode = get(record, modeSource);
  }
  const currency = get(record, currencySource);
  const href = getExploreUrl(currency, '{val}', mode)

  if (!href) {
    return <TextField
      {...props}
      source={source}
      record={record}
      label={label}
    />
  }

  const val = get(record, source);
  const fieldRecord = {val};

  return <UrlField
    {...props}
    source='val'
    record={fieldRecord}
    label={label}
    hrefpattern={href}
  />;
};

BlockchainUrlField.propTypes = {
  record: PropTypes.object,
  mode: PropTypes.string.isRequired, // 'transaction' or 'address'
  source: PropTypes.string.isRequired,
  currencySource: PropTypes.string,
};

BlockchainUrlField.defaultProps = {
  record: {},
  mode: 'transaction',
  addLabel: true,
};

export default BlockchainUrlField;
