export default function hashSpeedTrunc(hashSpeed, useSols = false, decimals = 2) {
  if (parseInt(hashSpeed, 0) === 0) return '0 H/s';
   let k = 1000,
       hSizes = ['H/s', 'KH/s', 'MH/s', 'GH/s', 'TH/s', 'PH/s'],
       solSizes = ['Sol/s', 'KSol/s', 'MSol/s', 'GSol/s', 'TSol/s', 'PSol/s'],
       sizes = useSols ? solSizes : hSizes,
       i = Math.floor(Math.log(hashSpeed) / Math.log(k));

   return parseFloat((hashSpeed / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}
