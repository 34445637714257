import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import cx from "classnames";
import {withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {GET} from 'providers/myRestProvider';
import dataProvider from "providers/myRestProvider";
import {NumberField} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {CONFIG} from "config";

class SummaryInfo extends React.Component {
  state = {
    balances: null,
    detailsVisible: false,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.version !== prevProps.version) {
      this.loadData();
    }
  }

  loadData() {
    if (this.props.balances) {
      let {balances, names} = this.props;
      if (names) {
        balances = balances.filter(t => names.includes(t.name));
      }
      this.setState({balances});
      return;
    }

    dataProvider(GET, `geth/balances`)
      .then(response => {
        const balances = response.data.filter(t => t.name === 'deposit');
        this.setState({balances})
      });
  }

  handleDetailsClick = () => {
    const {detailsVisible: detailsVisible} = this.state;
    this.setState({detailsVisible: !detailsVisible});
  };

  render() {
    const {classes: c, className, label, onlySummary, ...restProps} = this.props;
    const {balances, detailsVisible} = this.state;

    if (!balances) {
      return <div className={cx(className, c.root)}>
        <LinearProgress mode="indeterminate" className={c.progress}/>
      </div>;
    }

    const totalFiat = balances.reduce((sum, t) => sum + t.balance_fiat, 0);

    return <div className={cx(className, c.root)}>
      {<div className={c.summary}>
        {this.renderLabel(label || 'Summary', c.summaryLabel)}
        <NumberField
          record={{t: totalFiat}}
          source='t'
          options={{style: 'currency', currency: CONFIG.fiatCurrency}}
          className={cx(c.typo, c.value, c.fiat)}
        />
      </div>}
      {!onlySummary && balances.length && <Typography component='a' className={c.btnDetails} onClick={this.handleDetailsClick}>
        {detailsVisible ? 'Hide' : 'Show'} details
      </Typography>}
      {!onlySummary && balances.length && detailsVisible && <div className={c.balances}>{this.renderBalances(balances)}</div>}
    </div>;
  }

  renderLabel(label, className) {
    const {classes: c} = this.props;
    return <Typography
      component="span"
      className={cx(c.typo, className || c.label)}
    >
      {label}:
    </Typography>
  }

  renderBalances(balances) {
    const {classes: c} = this.props;
    return <table cellSpacing="0" cellPadding="0">
      <tbody>
      {balances.map(t => <tr key={t.currency}>
        <td>{this.renderLabel(t.currency)}</td>
        <td>
          <NumberField
            record={t}
            source='balance'
            options={{maximumFractionDigits: 5}}
            className={cx(c.typo, c.value)}
          />
        </td>
        <td className={c.fiatCell}>
          <NumberField
            record={t}
            source='balance_fiat'
            options={{style: 'currency', currency: CONFIG.fiatCurrency}}
            className={cx(c.typo, c.value, c.fiat)}
          />
        </td>
      </tr>)}
      </tbody>
    </table>
  }
}

SummaryInfo.propTypes = {
  balances: PropTypes.array,
  names: PropTypes.array,
  label: PropTypes.string,
  onlySummary: PropTypes.bool,
};

SummaryInfo.defaultProps = {};

const styles = theme => ({
  root: {
    color: theme.colors.text,
  },
  progress: {
    width: '100px',
  },
  typo: {
    display: 'inline',
  },
  label: {
    color: theme.colors.rounding,
  },
  summary: {
    display: 'inline-block',
  },
  summaryLabel: {
  },
  value: {
    marginLeft: '10px',
  },
  fiat: {
    color: theme.colors.controlPrimary,
  },
  btnDetails: {
    marginLeft: 10,
    cursor: 'pointer',
    display: 'inline-block',
    color: theme.colors.text,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  balances: {

  },
  fiatCell: {
    textAlign: 'right',
  }
});

const mapStateToProps = (state, props) => ({
  // grab viewVersion to react on "refresh" button
  version: state.admin.ui.viewVersion,
});
export default connect(mapStateToProps, {})(withStyles(styles)(SummaryInfo));
