import React from "react";
import _ from 'lodash';
import YesNoField from "fields/YesNoField";

const MoneyIsPaidField = ({record, source, showComment}) => {
  const money = _.get(record, source, {});
  const comment = money.is_paid && money.paid_cause || '';
  return <YesNoField source='is_paid'
                     record={money}
                     comment={comment}
                     showComment={showComment}
                     yesText='Paid'
                     noText='Free'/>
};

MoneyIsPaidField.defaultProps = {
  showComment: true,

  source: 'money',
  label: 'Is paid',
  addLabel: true,
};

export default MoneyIsPaidField;