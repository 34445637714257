import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
  SimpleShowLayout,
} from 'react-admin';
import AdaptiveLayout from "common/layout/AdaptiveLayout";
import JsonField from "fields/JsonField";

const StatsView = ({record, source, classes, ...props}) => {
  const {raw_stats_data: rawStatsData, stats_time: statsTime} = record;

  return <div className={classes.root}>
    <div className={classes.stattime}>Stats time: {statsTime}</div>
    <AdaptiveLayout>
      <SimpleShowLayout record={rawStatsData} {...props}>
        <JsonField source='raw_stats' collapsed={false} buttons={false}/>
        <JsonField source='stats_data' collapsed={false} buttons={false}/>
      </SimpleShowLayout>
      <SimpleShowLayout record={record} {...props}>
        <JsonField source='stats' collapsed={false} buttons={false}/>
      </SimpleShowLayout>
    </AdaptiveLayout>
  </div>;
};

StatsView.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

StatsView.defaultProps = {
  record: {},
};

const styles = theme => ({
  root: {
    paddingTop: '16px',
  },
  stattime: {
    padding: '0 24px',
  },
});

export default withStyles(styles)(StatsView);
