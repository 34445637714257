import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchEnd, fetchStart, showNotification} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import dataProvider, {fetchApi, GET} from "providers/myRestProvider";
import _ from 'lodash';
import SummaryInfo from "./SummaryInfo";
import Typography from '@material-ui/core/Typography';
import {getExploreUrl} from "utils/blockchain";

// this also defines view order
const LABELS = {
  "deposit": "Deposit",
  "hiveon": "HiveOS on Hiveon Devfee",
  "sparkpool": "SparkOS",
};

class Withdraw extends React.Component {
  state = {
    fetching: true,
    balances: null,
    unflushed_balances: null,
    input: null,
    results: null,
  };

  componentDidMount() {
    this.load();
  }

  load() {
    dataProvider(GET, `geth/balances`)
      .then(response => {
        const input = response.data.reduce((obj, t) => (
          {...obj, ...{[`${t.name}_${t.currency}_amount`]: t.spendable}}
        ), {});

        this.setState({balances: response.data, input, fetching: false})
      });
    dataProvider(GET, `geth/balances/unflushed`)
      .then(response => {
        this.setState({unflushed_balances: response.data})
      });
  }

  handleChange = e => {
    const set = {[e.target.name]: e.target.value};
    this.setState(({input}) => ({
      input: {...input, ...set}
    }));
  };

  handleSetMaxClick = (name, currency) => e => {
    const item = this.state.balances.find(t => t.name === name && t.currency === currency);
    this.setState({[`${name}_${currency}_amount`]: item.spendable});
  };

  handleWithdrawClick = () => {
    const {showNotification} = this.props;
    const requestItems = this.getRequestItems();

    if (!requestItems.length) {
      return;
    }

    const url = 'geth/withdraw';
    const data = {data: requestItems};

    this.doRequest(url, data).then((response) => {
      //showNotification('Withdraw succeeded');
      this.setState({results: response.data});
    }).catch((e) => {
      showNotification(e.message, 'warning')
    });
  };

  handleBackClick = () => {
    this.setState({results: null, input: null, fetching: true});
    this.load();
  };

  getRequestItems() {
    const {balances, input} = this.state;
    return balances.map(t => {
      const prefix = `${t.name}_${t.currency}`;
      const {
        [`${prefix}_address`]: to,
        [`${prefix}_amount`]: amount,
      } = input;
      if (to && amount) {
        const {name, currency} = t;
        return {name, to, currency, amount};
      } else {
        return null;
      }
    }).filter(t => !!t);
  }

  doRequest(url, data = null) {
    const {fetchStart, fetchEnd} = this.props;
    fetchStart();
    return fetchApi(url, {method: 'POST', body: data}).finally(fetchEnd);
  }

  render() {
    const {classes: c, ...restProps} = this.props;
    const {fetching, results} = this.state;

    if (fetching) {
      return <div className={c.root}>
        <LinearProgress mode="indeterminate" className={c.progress}/>
      </div>;
    }

    return <div className={c.root}>
      {results && this.renderResult()}
      {!results && this.renderForm()}
    </div>;
  }

  renderForm() {
    const {classes: c} = this.props;
    const {balances, unflushed_balances: unflushedBalances} = this.state;
    const btnDisabled = !this.getRequestItems().length;

    if (!balances.length) {
      return <div>Nothing to withdraw</div>
    }

    const groups = _.groupBy(balances, 'name');
    const sortedGroups = _.pickBy({...LABELS, ...groups}, _.isObject);

    return <div>
      <SummaryInfo className={c.topSummary}
                   balances={balances}
                   names={['deposit', 'hiveon']}
                   onlySummary={true}
                   label={'Summary of Deposit+HiveOS'}/>

      {Object.keys(sortedGroups).map(name => {
        const balances = sortedGroups[name];
        return <div className={c.group} key={name}>
          <div className={c.groupHead}>
            <h4 className={c.groupLabel}>{LABELS[name] || name}</h4>
            {this.renderAddresses(balances)}
          </div>
          <SummaryInfo className={c.summary} balances={balances}/>
          {name === 'deposit' && <SummaryInfo className={c.summary} balances={unflushedBalances} label={'Unflushed'}/>}
          {balances.map(this.renderFormItem.bind(this))}
        </div>
      })}
      <div>
        <Button color="primary" variant="contained" onClick={this.handleWithdrawClick} disabled={btnDisabled}>
          Withdraw
        </Button>
      </div>
    </div>
  }

  renderFormItem(item) {
    const {classes: c} = this.props;
    const {input} = this.state;
    const prefix = `${item.name}_${item.currency}`;
    return <div key={item.currency} className={c.row}>
      <TextField
        label={`${item.currency} address`}
        name={`${prefix}_address`}
        value={input[`${prefix}_address`] || ''}
        className={c.addressInput}
        onChange={this.handleChange}
        margin="normal"
      />
      <TextField
        label={`Withdraw amount`}
        name={`${prefix}_amount`}
        value={input[`${prefix}_amount`] || ''}
        type='number'
        step='any'
        className={c.amountInput}
        onChange={this.handleChange}
        margin="normal"
      />
      {/*<span>Total {item.currency}: {item.balance}</span>*/}
      <a className={c.setMaxBtn} onClick={this.handleSetMaxClick(item.name, item.currency)}>
        {'> set max <'}
      </a>
    </div>
  }

  renderResult() {
    const {classes: c} = this.props;
    const {results} = this.state;

    const sortedItems = _.sortBy(results, 'currency');
    const groups = _.groupBy(sortedItems, 'name');
    const sortedGroups = _.pickBy({...LABELS, ...groups}, _.isObject);

    return <div>
      {Object.keys(sortedGroups).map(name => {
        const items = sortedGroups[name];
        return <div className={c.group} key={name}>
          <h4 className={c.groupLabel}>{LABELS[name] || `${name} account`}</h4>
          {items.map(t => {
            return <div key={t.currency}>
              {t.txid && <TextField
                label={`${t.currency} transaction hash`}
                value={t.txid}
                className={c.txidInput}
                margin="normal"
                InputProps={{readOnly: true,}}
              />}

              {t.txid && <a className={c.aBtn} href={getExploreUrl(t.currency, t.txid, 'transaction')} target='_blank'>
                Explore
              </a>}

              {!t.txid && <TextField
                label={`${t.currency} error`}
                value={t.error || 'Error'}
                className={c.txidInput}
                margin="normal"
                InputProps={{readOnly: true,}}
              />}
            </div>
          })}
        </div>
      })}
      <div>
        <Button color="primary" variant="contained" onClick={this.handleBackClick}>
          Back
        </Button>
      </div>
    </div>
  }

  renderAddresses(balances) {
    const {classes: c} = this.props;
    return balances
      .filter(({currency}) => currency.indexOf('.') === -1) // only native currencies
      .map(item => {
        return <Typography className={c.groupAddress} key={item.currency}>
          {item.currency}:&nbsp;
          <a className={c.addressBtn} href={getExploreUrl(item.currency, item.address)} target='_blank'>
            {item.address}
          </a>
        </Typography>
      });
  }
}

Withdraw.propTypes = {};

Withdraw.defaultProps = {};

const styles = theme => ({
  root: {
    marginTop: 20,
  },
  progress: {
    marginTop: 16,
    width: 200,
  },
  topSummary: {
    margin: '10px 0 30px',
    fontSize: '110%',
  },
  group: {
    marginBottom: 70,
  },
  groupHead: {
    display: 'list-item',
  },
  groupLabel: {
    margin: 0,
    //display: 'list-item',
    // color: theme.colors.controlPrimary,
    display: 'inline-block',
    color: '#fff',
    textTransform: 'capitalize',
  },
  groupAddress: {
    marginLeft: 50,
    color: theme.colors.text,
    fontWeight: 'normal',
    display: 'inline-block',
  },
  summary: {
    margin: '10px 0',
  },
  row: {
    '&>*': {
      marginRight: 10,
    },
    '&>:last-child': {
      marginRight: 0,
    }
  },
  addressInput: {
    width: 450
  },
  amountInput: {
    width: 180
  },
  txidInput: {
    width: 1000
  },
  setMaxBtn: {
    //color: theme.colors.controlPrimary,
    cursor: "pointer",
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  aBtn: {
    color: theme.colors.controlPrimary,
    cursor: "pointer",
  },
  addressBtn: {
    color: theme.colors.text,
    cursor: "pointer",
  }
});

export default connect(null, {
  showNotification, fetchStart, fetchEnd
})(withStyles(styles)(Withdraw));
