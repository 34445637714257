import React from 'react';
import PropTypes from 'prop-types';
import {rrulestr} from 'rrule'

const RRuleField = ({record, source}) => {
  const value = record[source];
  if (!value) {
    return null;
  }

  try {
    const rrule = rrulestr(value);
    const humanText = rrule.toText();

    return <span title={value}>{humanText}</span>;
  }
  catch (e) {
    console.error('Could not parse rrule:', value, e);
    return <span>Could not parse rrule</span>
  }
};

RRuleField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

RRuleField.defaultProps = {
  record: {},
  addLabel: true,
};

export default RRuleField;
