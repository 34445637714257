import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

/**
 * Text field that does not exceed configured maxWidth property
 * and displays full content on hover or click
 */

class LongTextField extends React.Component {
  state = {
    expanded: false,
  };

  handleToggleClick = () => {
    const {expanded} = this.state;
    this.setState({expanded: !expanded});
  };

  render() {
    const {record, source, classes: c, className, maxWidth} = this.props;
    const {expanded} = this.state;
    const style = maxWidth > 0 && {
      maxWidth: `${ maxWidth }px`,
    };
    return <div className={ cx(c.main, className, {[c.expanded]: expanded}) } { ...{style} } onClick={ this.handleToggleClick }>
      <span>{ record[source] }</span>
    </div>
  }
}

LongTextField.propTypes = {
  maxWidth: PropTypes.number,

  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

LongTextField.defaultProps = {
  maxWidth: 300,
};

const expandedStyle = {
  overflow: 'visible',
  wordBreak: 'break-word',
  // position: 'relative',
  '& > span': {
    // background: '#ededed',
    // margin: '-5px -10px',
    // padding: '5px 10px',
  }
};
const styles = theme => ({
  main: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': expandedStyle,
  },
  expanded: expandedStyle
});

const EnhancedLongTextField = withStyles(styles)(LongTextField);

EnhancedLongTextField.defaultProps = {
  addLabel: true,
};

export default EnhancedLongTextField;