import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  SelectInput,
  ReferenceField,
  ReferenceArrayField,
  BooleanField,
  NullableBooleanInput,
} from 'react-admin';
import JsonField from '../fields/JsonField';
import Icon from '@material-ui/icons/Security';
import {ACL_ROLES} from '../constants/types';
import {CustomPagination} from "common/pagination/CustomPagination";
import CustomSelectField from "fields/CustomSelectField";
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import SortedSingleFieldList from "common/lists/SortedSingleFieldList";
import {TagChip} from "components/tags/TagChip";

const aclRoles = Object.keys(ACL_ROLES).map(id => ({id: id, name: ACL_ROLES[id]}));

export const AclIcon = Icon;

export const AclFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    {consumer !== 'farms' && <TextInput label='Farm ID' source='farm_id' alwaysOn/>}
    <SelectInput source='role' choices={aclRoles} alwaysOn/>
    <NullableBooleanInput source='twofa_required' label='2FA' alwaysOn/>
    <TextInput source='props' alwaysOn/>
  </Filter>
);

export const AclGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
      <FarmAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Owner' source='farm_user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    <CustomSelectField source='role' choices={aclRoles}/>
    <BooleanField source='twofa_required' label='2FA'/>
    <ReferenceArrayField label='Tags' reference='tags' source='tag_ids' sortable={false}>
      <SortedSingleFieldList sortBy='id' linkType='show'>
        <TagChip/>
      </SortedSingleFieldList>
    </ReferenceArrayField>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <BooleanField source='active'/>
    <JsonField source='props' sortable={false} preview={false}/>
  </Datagrid>
);

export const aclListProps = {
  sort: {field: 'id', order: 'asc'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

const AclList = ({permissions, ...props}) => (
  <List
    title='ACL'
    {...props}
    {...aclListProps}
    filters={<AclFilter/>}
    bulkActionButtons={false}
  >
    <AclGrid permissions={permissions}/>
  </List>
);

export default AclList;
