import React from "react";
import YesNoField from "fields/YesNoField";

const IsOnlineField = ({record = {}, showComment, showNo}) => {
  const {is_online: isOnline, stats_time: statsTime, boot_time: bootTime} = record;
  let comment;

  if (isOnline) {
    comment = `Booted at ${bootTime}`;
  }
  else {
    comment = `Was online at ${statsTime || 'unknown'}`;
  }

  return <YesNoField source='is_online'
                     record={record}
                     comment={comment}
                     showComment={showComment}
                     yesText='Online'
                     noText='Offline'
                     showNo={showNo}/>
};

IsOnlineField.defaultProps = {
  showComment: false,
  showNo: true,

  label: 'Is online',
  addLabel: true,
};

export default IsOnlineField;