import React from 'react';
import { ListView } from 'ra-ui-materialui/lib/list/List';
import ListController from 'ra-core/lib/controller/ListController';
import { withStyles } from '@material-ui/core/styles';

import NoopComponent from 'common/noop-component/NoopComponent';

function getFilter(filterDescriptor, record) {
  if (filterDescriptor
    && filterDescriptor.target
    && filterDescriptor.record
    && record[filterDescriptor.record]
  ) {
    return { [filterDescriptor.target]: record[filterDescriptor.record] };
  }

  // if impossible ot reach filter set inpossible condition
  // to show not found result
  return { id: null };
}

const CustomResourceList = (props) => {
  let customProps = Object.assign({}, props);
  customProps.filter = getFilter(props.customfilter, props.record);

  return (
    <ListController
      { ...customProps }
      resource={ props.custom }
      basePath={ '/' + props.custom }
      className={ props.classes.root }
    >
      {controllerProps =>
          <ListView
            {...props}
            {...controllerProps}
            title={ <NoopComponent/> }
          />
      }
    </ListController>
  );
}

const styles = theme => ({
  root: {
    '& div': {
      boxShadow: 'none',
    },
  },
});

export default withStyles(styles)(CustomResourceList);
