import randomColor from 'randomcolor';
import isJSON from 'is-json';

const ALGS_COLORS = {
  AlgoV3: "#60c1d4",
  bcd: "#1f94f1",
  blacke2s: "#32649f",
  cryptonight: "#a74866",
  equihash: "#f27e07",
  geek: "#68b6da",
  keccak: "#b4b60a",
  lyra2v2: "#7a2755",
  'myr-gr': "#ac445d",
  phi: "#ada830",
  sha256: "#5392a7",
  x11: "#3a718f",
  xevan: "#691479",
  zenprotocol: "#42cde2",
  Equihash: "#63c2de",
  bitcore: "#59a80f",
  bytom: "#58b9e3",
  cryptonight2: "#1aa89c",
  'equihash 96': "#708329",
  hmq1725: "#257a97",
  Ibry: "#820d80",
  lyra2z: "#20a68d",
  neoscrypt: "#7ea62c",
  phi2: "#db761c",
  skein: "#ab7535",
  x16r: "#b97922",
  yescrypt: "#6cb00f",
  MynerA: "#60beca",
  blake256r14: "#396bc2",
  c11: "#ec6b27",
  cuckoo: "#57c7dd",
  ethash: "#19ae84",
  hodl: "#5fced9",
  lyra2h: "#319561",
  lyra2z330: "#0d7d17",
  nist5: "#315fad",
  politimos: "#62bbdd",
  tribus: "#4243c1",
  x16s: "#145c2a",
  yescryptr16: "#85285f",
  allium:"#27d22b",
  black2b: "#a36333",
  cryptolight: "#6838c0",
  'darkcoin-mod': "#b5752d",
  'ethash-new': "#678430",
  hsr: "#6b4118",
  lyra2rev2: "#80ab1d",
  m7m: "#850485",
  pascal: "#198025",
  scrypt: "#96439d",
  wildkeccak: "#2d94e3",
  x17: "#793f27",
  yescryptr32: "#54b9cb",
  other: '#ababab',
};

const MINERS_COLORS = {
  claymore: '#9e0059',
  'claymore-z': '#ff0054',
  'claymore-x': '#ff5400',
  ewbf: '#ffbd00',
  ccminer: '#61210f',
  ethminer: '#ea2b1f',
  'sgminer-gm': '#edae49',
  dstm: '#f9df74',
  bminer: '#f9edcc',
  lolminer: '#5b7553',
  optiminer: '#8eb897',
  'xmr-stak': '#c3e8bd',
  xmrig: '#fa921',
  'cpuminer-opt': '#fe9920',
  custom: '#566e3d',
  asicminer: '#0c4767',
  other: '#ababab',
};

const BRAND_COLORS = {
  nvidia: '#1aaf91',
  amd: '#84276d',
  other: '#ababab',
};

const ASIC_MODELS_COLORS = {
  'Antminer T9+': '#2695e7',
  other: '#ababab',
};

const AMD_MODELS_COLORS = {
  'Radeon RX 470': '#ffbd00',
  other: '#ababab',
};

const NVIDIA_MODELS_COLORS = {
  'P106-100': '#b2405a',
  'GeForce GTX 1070': '#566e3d',
  other: '#ababab',
};

const COIN_COLORS = {
  eth: '#669BBC',
  btc: '#f3a712',
  xmr: '#E4572E',
  other: '#ababab',
};

const POOL_COLORS = {
  'ethermine.org': '#9e0059',
  'nanopool.org': '#ff0054',
  'nicehash.com': '#ff5400',
  '2miners.com': '#ffbd00',
  'hiveon.net': '#61210f',
  'sparkpool.com': '#ea2b1f',
  'f2pool.com': '#edae49',
  other: '#ababab',
};

export function getAlgoColor(algName) {
  return getColor(algName, ALGS_COLORS);
}

export function getMinerColor(minerName) {
  return getColor(minerName, MINERS_COLORS);
}

export function getGpuBrandColor(brandName) {
  return getColor(brandName, BRAND_COLORS);
}

export function getAsicModelColor(modelName) {
  return getColor(modelName, ASIC_MODELS_COLORS);
}

export function getAmdModelColor(modelName) {
  return getColor(modelName, AMD_MODELS_COLORS);
}

export function getNvidiaModelColor(modelName) {
  return getColor(modelName, NVIDIA_MODELS_COLORS);
}

export function getCoinColor(coinName) {
  return getColor(coinName.toLowerCase(), COIN_COLORS);
}

export function getPoolColor(pool) {
  return getColor(pool, POOL_COLORS);
}

export function getGeoColor(countryCode) {
  return getColor(countryCode);
}

export function getColor(name, colorObj) {
   if ((typeof colorObj === 'object') && colorObj[name]) {
     return colorObj[name];
   }

   let statsColors = localStorage.getItem('statsColors');
   let generatedColor = randomColor();

   if (!statsColors || !isJSON(statsColors)) {
     let statsColors = {
       [name]: generatedColor
     };

     localStorage.setItem('statsColors', JSON.stringify(statsColors));

     return generatedColor;
   }

   let storedColors = JSON.parse(statsColors);

   if ((typeof storedColors === 'object') && storedColors[name]) {
     return storedColors[name];
   }

   storedColors = {...storedColors, ...{ [name]: generatedColor} };

   localStorage.setItem('statsColors', JSON.stringify(storedColors));

   return generatedColor;
}
