import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import {fetchEnd, fetchStart, showNotification} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {ReferenceField} from "react-admin";
import FarmAliasField from "fields/FarmAliasField";
import {fetchApi} from "../../../providers/myRestProvider";

class Switch extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
    translate: PropTypes.func,
    startUndoable: PropTypes.func.isRequired,
  };

  handleClick = (event) => {
    const {record, resource, showNotification} = this.props;

    const url = `${resource}/${record.id}/switch`;
    this.doRequest(url).then(() => {
      showNotification('Command to workers has been sent');
    }).catch((e) => {
      showNotification(e.message, 'warning');
    });
  };

  doRequest(url, data = null) {
    const {fetchStart, fetchEnd} = this.props;
    fetchStart();
    return fetchApi(url, {method: 'POST', body: data}).finally(fetchEnd);
  }

  render() {
    const {classes: c, record} = this.props;
    const {switch_data: sw} = record.props;
    let text, label;

    if (!sw || !sw.source_project_id) {
      text = 'Switch all workers to copy farm';
      label = 'Switch to copy';
    } else {
      text = 'Switch all workers back to original farm';
      label = 'Switch back';
    }

    return (
      <FormControl className={c.main}>
        <Typography component="span">{text}</Typography>

        {sw && sw.source_project_id && <ReferenceField label='Source farm' source='source_project_id' record={sw} allowEmpty={true} reference='farms' basePath='/farms'>
          <FarmAliasField showId={true} showName={true}/>
        </ReferenceField>}

        {sw && sw.target_project_id && <ReferenceField label='Target farm' source='target_project_id' record={sw} allowEmpty={true} reference='farms' basePath='/farms'>
          <FarmAliasField showId={true} showName={true}/>
        </ReferenceField>}

        <div>
          <Button color='primary' variant='contained' onClick={this.handleClick}>
            {label}
          </Button>
        </div>

      </FormControl>
    );
  }
}

const styles = theme => ({
  main: {
    padding: '15px 0',
    '& > *': {
      marginBottom: 5,
    }
  },
});

export default connect(null, {
  fetchStart, fetchEnd, showNotification
})(withStyles(styles)(Switch));
