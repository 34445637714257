import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Icon from '@material-ui/icons/PieChart';
import {withStyles} from '@material-ui/core/styles';
import Popup from "reactjs-popup";
import Chart from "react-google-charts";

import dataProvider, {GET} from 'providers/myRestProvider';

import CoinIcon from 'common/coin-icon/CoinIcon';

import GeneralInfo from 'components/totalstats/GeneralInfo';
import Table from 'components/totalstats/Table';
import DonutsContainer from 'components/totalstats/DonutsContainer';
import Donut from 'components/totalstats/Donut';

import hashSpeedTrunc from 'utils/hashSpeedTrunc';
import {
  getAlgoColor,
  getMinerColor,
  getGpuBrandColor,
  getAsicModelColor,
  getAmdModelColor,
  getNvidiaModelColor,
  getCoinColor,
  getPoolColor,
  getGeoColor,
  getColor,
} from '../utils/colors';
import {getCountryName} from "utils/countries";

export const TotalStatsIcon = Icon;

export class TotalStats extends React.Component {
  state = {
    isFetching: true,
    stats: null
  };

  componentDidMount() {
    dataProvider(GET, 'total_stats').then(response => {
      this.setState({isFetching: false, stats: response})
    });
  }

  putHeader(data) {
    return (
      <GeneralInfo
        key='header'
        data={[
          {key: 'Users', value: data.users || 0},
          {key: 'Farms', value: data.projects || 0},
          {key: 'Workers', value: data.workers || 0},
          {key: 'GPUs', value: data.gpus || 0},
          {key: 'CPUs', value: data.cpus || 0},
          {key: 'Rigs', value: data.rigs || 0},
          {key: 'ASICs', value: data.asics || 0},
        ]}
      />
    );
  }

  putAlgorithmsTable(data) {
    if (!data.algohs || !Object.keys(data.algohs).length) {
      return null;
    }

    let algohs = Object.keys(data.algohs)
      .map(key => ({name: key, value: hashSpeedTrunc(data.algohs[key], key.includes('equihash'))}))
      .sort((current, next) => (current.name.localeCompare(next.name)));

    return (
      <Table
        key='algorithms'
        getColor={getAlgoColor}
        data={algohs}
      />
    );
  }

  putCoinsInfoTable(data) {
    let info = [];
    let {coinhs, coins_units} = data;

    if (coins_units && Object.keys(coins_units).length) {
      coins_units = flatten(coins_units);
      info = Object.keys(coins_units).map((key) => {
        return {name: key, value: coins_units[key]};
      });
    }

    if (coinhs && Object.keys(coinhs).length) {
      coinhs = flatten(coinhs);
      Object.keys(coinhs).forEach((key) => {
        let index = info.findIndex((item) => {
          return (item.name === key);
        });

        if (index === -1) {
          info.push({name: key, value: coinhs[key]});
          return;
        }

        let value = info[index].value;
        info.splice(index, 1);

        info.push({name: key, value: [value, hashSpeedTrunc(coinhs[key], key.includes('equihash'))]});
      });
    }

    if (info.length === 0) {
      return null;
    }

    info = info
      .map((item) => {
        return {name: item.name, value: item.value}
      })
      .sort((current, next) => (next.value[0] - current.value[0]));

    let getIcon = (name = '') => {
      let icoName = name.toLowerCase();

      if (name.includes('.')) {
        icoName = name.split('.')[0].toLowerCase()
      }

      return <CoinIcon name={icoName}/>;
    };

    return (
      <Table
        key='coins-info'
        title='Coins Info'
        getIcon={getIcon}
        data={info}
      />
    );
  }

  putAlgorithmsDonut(data) {
    if (!data.algos_units || !Object.keys(data.algos_units).length) {
      return null;
    }

    return this.putDonut({
      key: 'algorithms-gpus',
      title: 'Algorithms/GPUs',
      units: data.algos_units,
      getColor: getAlgoColor
    });
  }

  putMinersGpusDonut(data) {
    if (!data.miners_gpus || !Object.keys(data.miners_gpus).length) {
      return null;
    }

    return this.putDonut({
      key: 'miners-gpus',
      title: 'Miners/GPUs',
      units: data.miners_gpus,
      getColor: getMinerColor
    });
  }

  putBrandsGpuDonut(data) {
    if (!data.brands_gpus || !Object.keys(data.brands_gpus).length) {
      return null;
    }

    return this.putDonut({
      key: 'brands-gpus',
      title: 'GPU Brands',
      units: data.brands_gpus,
      getColor: getGpuBrandColor
    });
  }

  putModelsAsicsDonut(data) {
    if (!data.models_asics || !Object.keys(data.models_asics).length) {
      return null;
    }

    return this.putDonut({
      key: 'models-asics',
      title: 'ASIC Models',
      units: data.models_asics,
      getColor: getAsicModelColor
    });
  }

  putModelsAmdDonut(data) {
    if (!data.models_gpus
      || !data.models_gpus.amd
      || !Object.keys(data.models_gpus.amd).length
    ) {
      return null;
    }

    return this.putDonut({
      key: 'models-gpus-amd',
      title: 'AMD Models',
      units: data.models_gpus.amd,
      getColor: getAmdModelColor
    });
  }

  putModelsNvidiaDonut(data) {
    if (!data.models_gpus
      || !data.models_gpus.nvidia
      || !Object.keys(data.models_gpus.nvidia).length
    ) {
      return null;
    }

    return this.putDonut({
      key: 'models-gpus-nvidia',
      title: 'Nvidia Models',
      units: data.models_gpus.nvidia,
      getColor: getNvidiaModelColor
    });
  }

  putCoinUnitsDonut(data) {
    if (!data.coins_units || !Object.keys(data.coins_units).length) {
      return null;
    }

    return this.putDonut({
      key: 'coins-units',
      title: 'Coin Units',
      units: data.coins_units,
      getColor: getCoinColor
    });
  }

  putPoolsDonut(data) {
    if (!data.pools_units || !Object.keys(data.pools_units).length) {
      return null;
    }

    return this.putDonut({
      key: 'pools',
      title: 'Pools',
      units: data.pools_units,
      getColor: getPoolColor
    });
  }

  putGeoDonut(data) {
    if (!data.workers_geo || !Object.keys(data.workers_geo).length) {
      return null;
    }

    const units = {};
    const chartsData = [['Country', 'Workers count']];
    for (let key of Object.keys(data.workers_geo)) {
      const name = getCountryName(key) || 'Unknown';
      units[name] = data.workers_geo[key];
      chartsData.push([key, data.workers_geo[key]]);
    }

    const {classes: c} = this.props;

    console.log('chartsData=', chartsData);

    const popup = <Popup trigger={<a className={c.showMapBtn}>Show map</a>} modal>
      <Chart
        _width={'500px'}
        height={'600px'}
        chartType="GeoChart"
        data={chartsData}
        // Note: you will need to get a mapsApiKey for your project.
        // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
        mapsApiKey="AIzaSyBWXUX96DvthH66id96hp-GlSIyclgeTfc"
      />
    </Popup>;

    const title = <span>
      Workers Geo
      {popup}
    </span>;

    return this.putDonut({
      key: 'geo',
      title: title,
      units: units,
      getColor: getGeoColor
    });
  }

  putVersionsGpusDonut(data) {
    if (!data.versions_gpus || !Object.keys(data.versions_gpus).length) {
      return null;
    }

    return this.putDonut({
      key: 'versions-gpus',
      title: 'Rigs Versions',
      units: data.versions_gpus,
      getColor: getColor
    });
  }

  putVersionsAsicsDonut(data) {
    if (!data.versions_asics || !Object.keys(data.versions_asics).length) {
      return null;
    }

    return this.putDonut({
      key: 'versions-asics',
      title: 'ASICs Versions',
      units: data.versions_asics,
      getColor: getColor
    });
  }

  putDonut({key, title, units, getColor}) {
    const data = Object.keys(units)
      .map(name => {
        let value = units[name];
        let details = null;

        // if value is an object - we calculate summary item and pass the items as details
        if (typeof (value) === 'object') {
          const sum = Object.values(value).reduce((acc, v) => acc + v, 0);
          details = Object.keys(value)
            .map(key => ({name: key, value: value[key]}))
            .sort((a, b) => b.value - a.value);
          value = sum;
        }

        return {name, value, details};
      })
      .sort((a, b) => b.value - a.value);

    return <Donut
      key={key}
      title={title}
      getColor={getColor}
      data={data}
    />;
  }

  putDonutsContainer(data) {
    let donuts = [
      this.putAlgorithmsDonut(data),
      this.putMinersGpusDonut(data),
      this.putBrandsGpuDonut(data),
      this.putModelsAsicsDonut(data),
      this.putModelsAmdDonut(data),
      this.putModelsNvidiaDonut(data),
      this.putCoinUnitsDonut(data),
      this.putPoolsDonut(data),
      this.putGeoDonut(data),
      this.putVersionsGpusDonut(data),
      this.putVersionsAsicsDonut(data),
    ];

    donuts = donuts.filter((item => item !== null));

    if (donuts.length === 0) {
      return null;
    }

    return (
      <DonutsContainer key='donuts'>
        {donuts}
      </DonutsContainer>
    );
  }

  putContent(stats) {
    if (!stats) {
      return null;
    }

    return [
      this.putHeader(stats),
      this.putAlgorithmsTable(stats),
      this.putDonutsContainer(stats),
      this.putCoinsInfoTable(stats),
    ]
  }

  render() {
    const {isFetching, stats} = this.state;

    return <Card>
      <CardHeader title="Total Stats"/>
      <CardContent>

        {isFetching && <div>loading</div>}

        {!isFetching && this.putContent(stats)}

      </CardContent>
    </Card>
  }
}

const styles = theme => ({
  showMapBtn: {
    color: theme.colors.controlPrimary,
    marginLeft: 10,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export default withStyles(styles)(TotalStats);

/**
 * Flattens two-level stats object into single-level
 * @param {object} data
 * @return {object}
 */
function flatten(data) {
  const dataKeys = Object.keys(data);
  if (!dataKeys.length) {
    return data;
  }
  const k = dataKeys[0];
  if (typeof (data[k]) === 'object') {
    const flattened = {};
    dataKeys.forEach(key1 => {
      Object.keys(data[key1]).forEach(key2 => {
        flattened[`${key1}.${key2}`] = data[key1][key2];
      })
    });
    return flattened;
  }
  return data;
}
