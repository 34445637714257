import React from 'react';
import {
  SimpleShowLayout,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  ReferenceArrayField,
} from 'react-admin';

import JsonField from 'fields/JsonField';
import AdaptiveLayout from "common/layout/AdaptiveLayout";
import PreField from "fields/PreField";
import UserAliasField from "../../fields/UserAliasField";
import FarmAliasField from "../../fields/FarmAliasField";
import SortedSingleFieldList from "common/lists/SortedSingleFieldList";
import {TagChip} from "components/tags/TagChip";
import {CONFIG} from "config";
import YesNoField from "fields/YesNoField";
import MoneyIsPaidField from "./MoneyIsPaidField";
import MoneyOverdraftField from "./MoneyOverdraftField";
import IsLockedField from "./IsLockedField";

const FarmInfo = (props) => (
  <div>
    <AdaptiveLayout>
      <SimpleShowLayout {...props}>
        <TextField source='id'/>
        <FarmAliasField link={true} label='Name'/>
        <ReferenceField label='Owner' source='user_id' reference='users'>
          <UserAliasField showId={true} showName={true}/>
        </ReferenceField>
        <ReferenceField label='Payer' source='payer_id' reference='users' allowEmpty={true}>
          <UserAliasField showId={true} showName={true}/>
        </ReferenceField>
        <DateField source='created_at' showTime locales='uk-UA'/>
        <TextField source='farm_hash'/>
        {props.record.admin_comment && <PreField source='admin_comment'/>}
        <ReferenceArrayField label='Tags' reference='tags' source='tag_ids' sortable={false} allowEmpty={true}>
          <SortedSingleFieldList sortBy='id' linkType='show'>
            <TagChip/>
          </SortedSingleFieldList>
        </ReferenceArrayField>
      </SimpleShowLayout>

      <SimpleShowLayout {...props}>
        <MoneyIsPaidField/>
        <YesNoField source='nonfree'/>
        <MoneyOverdraftField/>
        <IsLockedField/>
        <DateField source='locked_at' showTime locales='uk-UA'/>
      </SimpleShowLayout>

      <SimpleShowLayout {...props}>
        <NumberField source='balance' options={{style: 'currency', currency: CONFIG.fiatCurrency}}/>
        <NumberField source='discount' options={{style: 'percent'}}/>
        <NumberField source='money.discount' options={{style: 'percent'}} label='Actual discount'/>
        <NumberField source='money.daily_cost' label='Daily cost' options={{style: 'currency', currency: CONFIG.fiatCurrency}}/>
      </SimpleShowLayout>

      <SimpleShowLayout {...props}>
        <JsonField source='money.daily_use' label='Daily use' collapsed={false} buttons={false}/>
      </SimpleShowLayout>
    </AdaptiveLayout>

    <AdaptiveLayout>
      <SimpleShowLayout {...props}>
        <JsonField source='props' collapsed={false} buttons={false} wide={true}/>
      </SimpleShowLayout>

      <SimpleShowLayout {...props}>
        <JsonField source='money' collapsed={false} buttons={false} wide={true}/>
      </SimpleShowLayout>
    </AdaptiveLayout>

  </div>
);

export default FarmInfo;
