import React from 'react';
import {refreshView} from 'react-admin';
import {connect} from 'react-redux';
import ConvertToEthButton from "./ConvertToEthButton";

class ConvertToEthBulkButton extends React.Component {
	handleDone = () => {
		const {refreshView} = this.props;
		refreshView();
	};

	render() {
		const props = this.props;
		return <ConvertToEthButton done={this.handleDone} {...props}/>
	}
}

export default connect(null, {
	refreshView
})(ConvertToEthBulkButton);
