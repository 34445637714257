import React from "react";
import PropTypes from 'prop-types';
import cx from "classnames";
import {DateField, NumberField} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import {CONFIG} from "config";

const LastPayoutField = (props) => {
  const {record, source, classes: c, ...restProps} = props;
  const payout = record[source];
  if (!payout) {
    return null;
  }

  return <div>
    <NumberField record={payout} source='amount_usd' options={{style: 'currency', currency: CONFIG.fiatCurrency}}/>
    <DateField record={payout} source='created_at' showTime locales='uk-UA'/>
  </div>
};

LastPayoutField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

LastPayoutField.defaultProps = {
  record: {},
};

const styles = theme => ({

});

const EnhancedLastPayoutField = withStyles(styles)(LastPayoutField);

EnhancedLastPayoutField.defaultProps = {
  addLabel: true,
};

export default EnhancedLastPayoutField;
