import React from 'react';
import {
  Edit,
  TextField,
  TextInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  LongTextInput, AutocompleteInput, ReferenceInput,
} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';

import NoopComponent from 'common/noop-component/NoopComponent';
import AdaptiveLayout from 'common/layout/AdaptiveLayout';
import Enroll from 'common/enroll/Enroll';

import Toolbar from 'common/toolbar-save-button/ToolbarSaveButton';
import AllPools from 'components/users/AllPools';
import BanToggle from 'components/users/BanToggle';
import ResetTwofa from 'components/users/ResetTwofa';
import ResetWhitelist from 'components/users/ResetWhitelist';
import IsBusiness from "components/users/IsBusiness";
import CustomSimpleForm from "common/forms/CustomSimpleForm";
import {asyncValidateEdit} from "common/forms/Utils";
import {userOptionRenderer} from "fields/UserAliasField";


const EditAttributes = ({permissions: p = {}, ...props}) => (
  <AdaptiveLayout>
    <Edit {...props} title={<NoopComponent/>}>
      <CustomSimpleForm toolbar={<Toolbar/>} asyncValidate={asyncValidateEdit}>
        <TextField source='id'/>
        {p['admin.users.manage.email'] && <TextInput source='email'/>}
        {p['admin.users.manage'] && <TextInput source='name'/>}
        {p['admin.users.manage'] && <TextInput source='login'/>}
        {p['admin.users.manage.password'] && <TextInput source='password' type='password'/>}
        {p['admin.users.manage'] && <TextInput source='promocode'/>}
        {p['admin.users.manage'] && <NumberInput source='discount' step={0.01}/>}
        {p['admin.users.manage.referral'] && <NumberInput source='referral_reward' step={0.01}/>}
        {p['admin.roles.manage'] && <ReferenceArrayInput source="role_ids" reference="roles" label="Roles"
                                                         sort={{field: 'name', order: 'ASC'}} perPage={10000}>
          <SelectArrayInput optionText="display_name"/>
        </ReferenceArrayInput>}
        {p['admin.users.manage.referral'] && <ReferenceInput source="referral_id" reference="users" label="Referral" allowEmpty={true}>
          <AutocompleteInput optionText={userOptionRenderer}/>
        </ReferenceInput>}
        {p['admin.users.manage.comment'] && <LongTextInput source='admin_comment'/>}
      </CustomSimpleForm>
    </Edit>

    <Edit {...props} title={<NoopComponent/>}>
      <CustomSimpleForm toolbar={<NoopComponent/>}>
        {p['admin.users.manage.ban'] && <BanToggle noButton={false}/>}
        {p['admin.users.manage.twofa'] && <ResetTwofa noButton={false}/>}
        {p['admin.users.manage.whitelist'] && <ResetWhitelist noButton={false}/>}
        {p['admin.users.manage'] && <AllPools noButton={false}/>}
        {p['admin.users.manage'] && <IsBusiness noButton={false}/>}
      </CustomSimpleForm>
    </Edit>

    <Edit {...props} title={<NoopComponent/>}>
      <CustomSimpleForm toolbar={<NoopComponent/>}>
        {p['admin.users.manage.gift'] && <Enroll/>}
      </CustomSimpleForm>
    </Edit>
  </AdaptiveLayout>
);

const styles = theme => ({
  root: {
    ['& div']: { // eslint-disable-line no-useless-computed-key
      boxShadow: 'none',
    }
  }
});

export default withStyles(styles)(EditAttributes);
