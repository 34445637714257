import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

let YesNoField = (props) => {
  const {
    record,
    source,
    comment,
    classes: c,
    yesText,
    noText,
    showComment,
    showYes,
    showNo
  } = props;
  const value = _.get(record, source, false);

  return <Typography component='span'>
    <span className={value ? c.yes : c.no} title={!showComment && comment || ''}>
      {value && showYes && yesText || ''}
      {!value && showNo && noText || ''}
    </span>
    {showComment && <span className={c.comment}>{comment ? ` (${comment})` : ''}</span>}
  </Typography>
};

YesNoField.propTypes = {
  comment: PropTypes.string,
  showComment: PropTypes.bool,
  showYes: PropTypes.bool,
  yesText: PropTypes.any,
  showNo: PropTypes.bool,
  noText: PropTypes.any,

  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const styles = theme => ({
  yes: {
    color: theme.colors.controlPrimary,
  },
  no: {
    // color: theme.colors.text,
  },
  comment: {
    color: theme.colors.text,
  },
});

YesNoField = withStyles(styles)(YesNoField);

YesNoField.defaultProps = {
  showComment: true,
  showYes: true,
  yesText: 'Yes',
  showNo: true,
  noText: 'No',

  addLabel: true,
};

export default YesNoField;
