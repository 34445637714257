import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

class BooleanField extends Component {
  static propTypes = {
    text: PropTypes.string,
    value: PropTypes.bool,
  };

  render () {
    return (
      <span className={ this.props.classes.main }>
        <span className={ this.props.classes.label }>{ this.props.text }</span>
        <Typography>
          { this.props.value ? <TrueIcon/> : <FalseIcon/> }
        </Typography>
      </span>
    );
  };
}


const styles = theme => ({
  main: {
    position: 'relative',
    color: theme.colors.text,
    padding: 0,
    fontSize: '1rem',
    lineHeight: 1,
  },
  label: {
    fontSize: '0.75rem',
  }
});

export default withStyles(styles)(BooleanField);
