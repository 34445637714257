import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  SelectInput,
  ReferenceField,
} from 'react-admin';
import JsonField from 'fields/JsonField';
import Icon from '@material-ui/icons/FlashOn';
import {TRANSACTION_TYPES} from 'constants/types';
import {CustomPagination} from "../common/pagination/CustomPagination";
import CustomSelectField from "fields/CustomSelectField";
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import {CONFIG} from "config";

const allTypeIds = Object.keys(TRANSACTION_TYPES).map(id => ({id: +id, name: TRANSACTION_TYPES[id]}));

export const TransactionsIcon = Icon;

export const TransactionsFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    {consumer !== 'farms' && <TextInput label='Farm ID' source='farm_id' alwaysOn/>}
    <SelectInput label='Type ID' source='type_id' choices={allTypeIds} alwaysOn/>
    <TextInput source='props' alwaysOn/>
  </Filter>
);

export const TransactionsGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <CustomSelectField source='type_id' choices={allTypeIds}/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
      <FarmAliasField/>
    </ReferenceField>}
    <TextField source='amount' label={`Amount, ${CONFIG.fiatCurrency}`}/>
    <JsonField source='daily_use' sortable={false} preview={false}/>
    <JsonField source='props' sortable={false} preview={false}/>
    <TextField source='rigs_used' label='Rigs'/>
    <TextField source='asics_used' label='ASICs'/>
    <ReferenceField label='Props User' source='props.user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>
    <ReferenceField label='Props Farm' source='props.project_id' reference='farms' allowEmpty={true}>
      <TextField source='name'/>
    </ReferenceField>
    <ReferenceField label='Admin' source='props.admin_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>
  </Datagrid>
);

export const transactionsListProps = {
  sort: {field: 'id', order: 'desc'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

const TransactionsList = ({permissions, ...props}) => (
  <List
    title='Transactions'
    {...props}
    {...transactionsListProps}
    filters={<TransactionsFilter/>}
    bulkActionButtons={false}
  >
    <TransactionsGrid permissions={permissions}/>
  </List>
);

export default TransactionsList;
