import React from "react";
import PropTypes from 'prop-types';
import cx from "classnames";
import { NumberField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import {CONFIG} from "config";

const TotalUsdField = (props) => {
  const {record, source, threshold, classes: c, ...restProps} = props;
  const val = +record[source];
  const className = val >= threshold ? 'ok' : 'dimmed';
  return <NumberField
    { ...restProps }
    record={ record }
    source={ source }
    options={ {style: 'currency', currency: CONFIG.fiatCurrency} }
    className={ cx(c.val, c[className]) }
  />
};

TotalUsdField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  threshold: PropTypes.number.isRequired,
};

TotalUsdField.defaultProps = {
  record: {},
};

const styles = theme => ({
  main: {},
  val: {
    textAlign: 'right'
  },
  ok: {
    color: '#25bd25',
    fontWeight: 'bold',
  },
  dimmed: {
    color: theme.colors.rounding,
  },
});

const EnhancedTotalUsdField = withStyles(styles)(TotalUsdField);

EnhancedTotalUsdField.defaultProps = {
  addLabel: true,
};

export default EnhancedTotalUsdField;
