import React from 'react';
import {
  SimpleShowLayout,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  EmailField,
  ReferenceArrayField,
  ChipField,
} from 'react-admin';

import JsonField from 'fields/JsonField';
import AdaptiveLayout from "common/layout/AdaptiveLayout";
import SortedSingleFieldList from "../../common/lists/SortedSingleFieldList";
import PreField from "fields/PreField";
import UserAliasField from "fields/UserAliasField";
import {CONFIG} from "config";
import YesNoField from "fields/YesNoField";
import IsBannedField from "./IsBannedField";

const UserInfo = (props) => (
  <div>
    <AdaptiveLayout>
      <SimpleShowLayout {...props}>
        <TextField source='id'/>
        <TextField source='login'/>
        <TextField source='name'/>
        <EmailField source='email'/>
        <TextField source='promocode'/>
        {props.record.referral_id && <ReferenceField label='Referral' source='referral_id' reference='users' allowEmpty={true}>
          <UserAliasField showId={true} showName={true}/>
        </ReferenceField>}
        {props.record.role_ids && <ReferenceArrayField label="Roles" reference="roles" source="role_ids">
          <SortedSingleFieldList sortBy="id">
            <ChipField source="name"/>
          </SortedSingleFieldList>
        </ReferenceArrayField>}
        <DateField source='created_at' showTime locales='uk-UA'/>
        {props.record.admin_comment && <PreField source='admin_comment'/>}
      </SimpleShowLayout>

      <SimpleShowLayout {...props}>
        <IsBannedField/>
        <YesNoField source='has_twofa'/>
        <YesNoField source='has_whitelist'/>
        <YesNoField source='all_pools'/>
        <YesNoField source='business'/>
      </SimpleShowLayout>

      <SimpleShowLayout {...props}>
        <NumberField source='balance' options={{style: 'currency', currency: CONFIG.fiatCurrency}}/>
        <NumberField source='discount' options={{style: 'percent'}}/>
        <NumberField source='referral_reward'/>
        <JsonField source='referral_balance' collapsed={false} buttons={false}/>
      </SimpleShowLayout>
    </AdaptiveLayout>

    <SimpleShowLayout {...props}>
      <JsonField source='props' collapsed={false} buttons={false} wide={true}/>
    </SimpleShowLayout>
  </div>
);

export default UserInfo;
