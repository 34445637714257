import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  SelectInput,
  ReferenceField,
  ShowButton,
  NullableBooleanInput,
} from 'react-admin'
import JsonField from 'fields/JsonField';
import Icon from '@material-ui/icons/Memory';
import {PLATFORMS} from "../constants/types";
import {CustomPagination} from "../common/pagination/CustomPagination";
import CustomSelectField from "fields/CustomSelectField";
import FarmAliasField from "fields/FarmAliasField";
import UserAliasField from "fields/UserAliasField";
import IPField from "fields/IPField";
import WorkerAliasField from "fields/WorkerAliasField";
import BillingDataSummary from "components/workers/BillingDataSummary";
import IsOnlineField from "components/workers/IsOnlineField";

const platforms = Object.keys(PLATFORMS).map(id => ({id: +id, name: PLATFORMS[id]}));

export const WorkersIcon = Icon;

export const WorkersFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    <TextInput label='ID' source='id' alwaysOn/>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    {consumer !== 'farms' && <TextInput label='Farm ID' source='farm_id' alwaysOn/>}
    <TextInput label='Name' source='name' alwaysOn/>
    <SelectInput label='Platform' source='platform' choices={platforms} alwaysOn/>
    <NullableBooleanInput source='online' alwaysOn/>
    <TextInput label='Referral' source='referral_id'/>
    <TextInput label='Remote IP' source='remote_ip'/>
    <TextInput source='props'/>
  </Filter>
);

export const WorkersGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    <WorkerAliasField link={true} label='Name'/>
    <CustomSelectField source='platform' choices={platforms}/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
      <FarmAliasField/>
    </ReferenceField>}
    <ReferenceField label='Referral' source='referral_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>
    <IsOnlineField/>
    <BillingDataSummary source='billing_data' label='Billing type' sortable={false}/>
    <IPField source='remote_ip'/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <JsonField source='props' sortable={false} preview={false}/>
    <ShowButton/>
  </Datagrid>
);

export const workersListProps = {
  sort: {field: 'id', order: 'ASC'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

const WorkersList = ({permissions, ...props}) => (
  <List
    title='Workers'
    {...props}
    {...workersListProps}
    filters={<WorkersFilter/>}
    bulkActionButtons={false}
  >
    <WorkersGrid permissions={permissions}/>
  </List>
);

export default WorkersList;
