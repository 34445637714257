import React from 'react';
import {
  Show,
  TextField,
  DateField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
} from 'react-admin';
import {TAG_TYPES} from "constants/types";
import CustomSelectField from "fields/CustomSelectField";
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import CustomResourceList from "common/custom-resource-list/CustomResourceList";
import {WorkersFilter, WorkersGrid, workersListProps} from "resources/workers";
import TagField from "fields/TagField";
import {FarmsFilter, FarmsGrid, farmsListProps} from "resources/farms";

const tagTypes = Object.keys(TAG_TYPES).map(id => ({id: +id, name: TAG_TYPES[id]}));

export const TagShow = ({permissions, ...props}) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="info">
        <TextField source="id"/>
        <TagField source='name'/>
        <CustomSelectField source='type_id' choices={tagTypes}/>
        <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
          <FarmAliasField showId={true}/>
        </ReferenceField>
        <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
          <UserAliasField showId={true} showName={true}/>
        </ReferenceField>
        <DateField source='created_at' showTime locales='uk-UA'/>
        <TextField source="workers_count"/>
        <TextField source="farms_count"/>
      </Tab>
      {permissions && permissions['admin.workers'] && <Tab label='workers'>
        <CustomResourceList
          key='custom-resource-list-workers'
          {...props}
          {...workersListProps}
          custom='workers'
          customfilter={{target: 'tag_id', record: 'id'}}
          filters={<WorkersFilter/>}
          bulkActionButtons={false}
        >
          <WorkersGrid/>
        </CustomResourceList>
      </Tab>}
      {permissions && permissions['admin.farms'] && <Tab label='farms'>
        <CustomResourceList
          key='custom-resource-list-projects'
          {...props}
          {...farmsListProps}
          custom='farms'
          customfilter={{target: 'tag_id', record: 'id'}}
          filters={<FarmsFilter/>}
          bulkActionButtons={false}
        >
          <FarmsGrid/>
        </CustomResourceList>
      </Tab>}
    </TabbedShowLayout>
  </Show>
);
