import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  NumberField,
  ReferenceField,
  EditButton, NullableBooleanInput,
} from 'react-admin';
import JsonField from 'fields/JsonField';

import Icon from '@material-ui/icons/Dns';
import { CustomPagination } from 'common/pagination/CustomPagination';
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import {CONFIG} from "config";
import MoneyIsPaidField from "components/farms/MoneyIsPaidField";
import IsLockedField from "components/farms/IsLockedField";

export const FarmsIcon = Icon;

export const FarmsFilter = ({consumer, ...props}) => (
	<Filter {...props}>
    <TextInput label='Farm ID' source='id' alwaysOn/>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    <TextInput label='Name' source='name' alwaysOn/>
    <TextInput label='Payer ID' source='payer_id' />
    <NullableBooleanInput label='Locked' source='is_locked' />
    <TextInput source='farm_hash' />
    <TextInput source='props' />
  </Filter>
);

export const FarmsGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid { ...props }>
    <TextField source='id'/>
    {consumer !== 'users' && <ReferenceField label='Owner' source='user_id' reference='users'>
      <UserAliasField/>
    </ReferenceField>}
    <FarmAliasField link={true} label='Name'/>
    <MoneyIsPaidField showComment={false} label='Paid'/>
    <NumberField source='balance' options={{ style: 'currency', currency: CONFIG.fiatCurrency }}/>
    <NumberField source='discount' options={{ style: 'percent' }}/>
    <NumberField source='money.daily_cost' label='Daily cost' options={{ style: 'currency', currency: CONFIG.fiatCurrency }}/>
    <JsonField source='money.daily_use' label='Daily use' sortable={false} preview={false}/>
    <IsLockedField label='Locked' showNo={false}/>
    {/*<DateField source='locked_at' showTime locales='uk-UA' />*/}
    <ReferenceField label='Payer' source='payer_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <JsonField source='props' sortable={ false } preview={ false } />
    {p && p['admin.farms.manage.*'] && <EditButton/>}
  </Datagrid>
);

export const farmsListProps = {
  sort: {field: 'id', order: 'ASC'},
  pagination: <CustomPagination />,
  perPage: 50,
};

const FarmsList = ({permissions, ...props}) => (
  <List
    title='Farms'
    { ...props }
    { ...farmsListProps }
    filters={ <FarmsFilter/> }
    bulkActionButtons={ false }
  >
    <FarmsGrid permissions={permissions}/>
  </List>
);

export default FarmsList;
