import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {BILLING_TYPES} from "constants/types";

let BillingDataField = ({source, record = {}, classes: c}) => {
  const billingData = _.get(record, source, []);

  return <Typography component='span'>
    {billingData.map(item => <div key={item.type}>
      <div className={c.typeName}>{BILLING_TYPES[item.type]}</div>
      <div className={c.itemBody}>{JSON.stringify(item, null, 2)}</div>
    </div>)}
  </Typography>
};

BillingDataField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const styles = theme => ({
  typeName: {

  },
  itemBody: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    lineHeight: '14px',
    fontFamily: 'monospace',
  },
});

BillingDataField = withStyles(styles)(BillingDataField);

BillingDataField.defaultProps = {
  addLabel: true,
};

export default BillingDataField;
