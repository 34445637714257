import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  ReferenceField,
  SelectInput,
} from 'react-admin';
import {CustomPagination} from "common/pagination/CustomPagination";
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import BalanceField from "components/deposit-wallets/BalanceField";
import JsonField from "fields/JsonField";
import {DEPOSIT_WALLET_PROVIDERS} from "constants/types";
import CustomSelectField from "fields/CustomSelectField";
import AddressField from "fields/AddressField";

export {default as DepositWalletsIcon} from '@material-ui/icons/AttachMoney';

const allProviders = Object.keys(DEPOSIT_WALLET_PROVIDERS).map(id => ({id: id, name: DEPOSIT_WALLET_PROVIDERS[id]}));

export const DepositWalletsFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    <TextInput source='id' alwaysOn/>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    {consumer !== 'farms' && <TextInput label='Farm ID' source='farm_id' alwaysOn/>}
    <SelectInput source='provider' choices={allProviders} alwaysOn/>
    <TextInput source='currency' alwaysOn/>
    <TextInput source='address' alwaysOn/>
  </Filter>
);

export const DepositWalletsGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
      <FarmAliasField/>
    </ReferenceField>}
    <CustomSelectField source='provider' choices={allProviders}/>
    <AddressField source='address'/>
    <TextField source='currency'/>
    <BalanceField source='balance'/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <DateField source='synced_at' showTime locales='uk-UA'/>
    <DateField source='flushed_at' showTime locales='uk-UA'/>
    <DateField source='locked_at' showTime locales='uk-UA'/>
    <JsonField source='props' sortable={false} preview={false}/>
    <JsonField source='state' sortable={false} preview={false}/>
  </Datagrid>
);

export const depositWalletsListProps = {
  sort: {field: 'id', order: 'desc'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

export const DepositWalletsList = ({permissions, ...props}) => (
  <List
    title='Deposit wallets'
    {...props}
    {...depositWalletsListProps}
    filters={<DepositWalletsFilter/>}
    bulkActionButtons={false}
  >
    <DepositWalletsGrid permissions={permissions}/>
  </List>
);

