import React from 'react';
import PropTypes from 'prop-types';
import { fetchApi } from 'providers/myRestProvider';
import {fetchEnd, fetchStart, showNotification, refreshView} from 'react-admin';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';

class RetryPayoutButton extends React.Component {
	static propTypes = {
		done: PropTypes.func,
		record: PropTypes.object,
	};

	handleClick = () => {
		const {record, done, showNotification, refreshView} = this.props;
		this.doRequest(`referrals/payouts/${record.id}/retry`).then(() => {
			showNotification('Payout succeeded');
			refreshView();
		}).catch((e) => {
			showNotification(e.message, 'warning')
		}).then(done);
	};

	doRequest(url, data = null) {
		const {fetchStart, fetchEnd} = this.props;
		fetchStart();
		return fetchApi(url, {method: 'POST', body: data}).finally(fetchEnd);
	}

	render() {
		const {record, children} = this.props;
		const status = record.status;
		if (status !== 'dropped') {
			return null;
		}
		return <Button color="primary" variant="contained" onClick={this.handleClick}>
			{children || 'Retry payout'}
		</Button>;
	}
}

export default connect(null, {
	showNotification, refreshView, fetchStart, fetchEnd
})(RetryPayoutButton);
