import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import cx from "classnames";
import {withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {GET} from 'providers/myRestProvider';
import dataProvider from "providers/myRestProvider";
import {NumberField} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {CONFIG} from "config";

class SummaryInfo extends React.Component {
  state = {
    data: null
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.version !== prevProps.version) {
      this.loadData();
    }
  }

  loadData() {
    const {threshold} = this.props;

    dataProvider(GET, `referrals/summary?threshold=${threshold}`)
      .then(response => {
        this.setState({data: response})
      });
  }

  render() {
    const {classes: s, ...restProps} = this.props;
    const {data} = this.state;

    if (!data) {
      return <div className={s.root}>
        <LinearProgress mode="indeterminate" className={s.progress}/>
      </div>;
    }

    const label = function (label) {
      return <Typography
        component="span"
        className={cx(s.typo, s.label)}
      >
        {label}:
      </Typography>
    };

    // add 0.5% for transaction costs
    let isEnough = data.wallet_balance_eth && data.wallet_balance_eth/data.total_payable_balance_eth - 1 >= 0.05;

    return <div className={s.root}>
      <div className={s.cell}>
        {label('Total')}
        <NumberField
          record={data}
          source='total_balance_usd'
          options={{style: 'currency', currency: CONFIG.fiatCurrency}}
          className={cx(s.typo, s.value)}
        />
      </div>
      <div className={s.cell}>
        {label('To pay')}
        <NumberField
          record={data}
          source='total_payable_balance_usd'
          options={{style: 'currency', currency: CONFIG.fiatCurrency}}
          className={cx(s.typo, s.value, s.ok)}
        />
        <NumberField
          record={data}
          source='total_payable_balance_eth'
          options={{style: 'currency', currency: 'ETH', maximumFractionDigits: 20}}
          className={cx(s.typo, s.value)}
        />
      </div>
      {data.wallet_balance_eth && <div className={s.cell}>
        {label('Wallet balance')}
        <NumberField
          record={data}
          source='wallet_balance_usd'
          options={{style: 'currency', currency: CONFIG.fiatCurrency}}
          className={cx(s.typo, s.value, isEnough ? s.ok : s.low)}
        />
        <NumberField
          record={data}
          source='wallet_balance_eth'
          options={{style: 'currency', currency: 'ETH', maximumFractionDigits: 20}}
          className={cx(s.typo, s.value)}
        />
      </div>}
    </div>;
  }
}

SummaryInfo.propTypes = {
  threshold: PropTypes.number.isRequired,
};

SummaryInfo.defaultProps = {};

const styles = theme => ({
  root: {
    color: theme.colors.text,
    padding: '5px 12px',
    display: 'flex',
  },
  progress: {
    width: '100px',
  },
  cell: {
    marginLeft: '25px',
    '&:first-child': {
      marginLeft: 0,
    }
  },
  typo: {
    display: 'inline'
  },
  label: {
    color: theme.colors.rounding,
  },
  value: {
    marginLeft: '10px',
  },
  ok: {
    color: '#25bd25',
    fontWeight: 'bold',
  },
  low: {
    color: '#ff3e48',
    fontWeight: 'bold',
  }
});

const mapStateToProps = (state, props) => ({
  // grab viewVersion to react on "refresh" button
  version: state.admin.ui.viewVersion,
});
export default connect(mapStateToProps, {})(withStyles(styles)(SummaryInfo));
