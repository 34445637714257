import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  SimpleForm,
} from "react-admin";
import {withStyles} from '@material-ui/core/styles';
import {fetchApi} from "../../providers/myRestProvider";
import _ from 'lodash';
import YamlField from "../../fields/YamlField";

class Edit extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.isReadonly = !props.permissions['admin.settings.manage'];

    let settings = props.settings;
    settings.brand_pools = JSON.parse(settings.brand_pools || null);
    this.state = {
      settings: settings,
      touched: {},
      saving: false,
    };
  }

  brandPoolsChanged(value) {
    this.updateSetting('brand_pools', value);
  }

  updateSetting(key, value) {
    this.setState(({settings, touched}) => {
      return {
        settings: {
          ...settings,
          [key]: value,
        },
        touched: {
          ...touched,
          [key]: true,
        }
      };
    });
  }

  handleSaveClick = () => {
    const {showNotification, onSave} = this.props;

    const data = this.getRequestData();
    if (!data) {
      return;
    }

    this.doRequest('settings', data).then((response) => {
      //showNotification('Withdraw succeeded');
      this.setState({touched: {}});

      if (onSave) {
        onSave();
      }

    }).catch((e) => {
      showNotification(e.message, 'warning')
    });
  };

  getRequestData() {
    // return only touched values
    const {settings, touched} = this.state;
    const data = _.pick(settings, Object.keys(touched));
    return _.isEmpty(data) ? null : data;
  }

  doRequest(url, data = null) {
    const {fetchStart, fetchEnd} = this.props;
    fetchStart();
    this.setState({saving: true});
    return fetchApi(url, {method: 'PATCH', body: data})
      .finally(fetchEnd)
      .finally(() => {
        this.setState({saving: false});
      });
  }

  fields() {
    return <YamlField
        name="brand_pools"
        label="Brand Pools"
        disabled={this.isReadonly}
        value={this.state.settings['brand_pools']}
        onChanged={this.brandPoolsChanged.bind(this)}
    />
  }

  render() {
    const {classes: c} = this.props;

    if (this.isReadonly) {
      return this.fields()
    }

    return <SimpleForm save={this.handleSaveClick} saving={this.state.saving} className={c.root}>
      {this.fields()}
    </SimpleForm>
  }
}

Edit.propTypes = {
  permissions: PropTypes.object.isRequired,
  onSave: PropTypes.func,
};

Edit.defaultProps = {};

const styles = theme => ({
  root: {
    '& fieldset': {
      margin: '10px 0',
      width: '25%',
    }
  },
});

export default connect(null, {
  showNotification, fetchStart, fetchEnd
})(withStyles(styles)(Edit));
