const exploreUrls = {
  ETC: {
    address: 'https://blockscout.com/etc/mainnet/address/{val}',
    transaction: 'https://blockscout.com/etc/mainnet/tx/{val}'
  },
  ETH: {
    address: 'https://etherscan.io/address/{val}',
    transaction: 'https://etherscan.io/tx/{val}'
  },
  BNB: {
    address: 'https://bscscan.com/address/{val}',
    transaction: 'https://bscscan.com/tx/{val}'
  }
};

const erc20Tokens = [
  'TUSD',
  'USDC',
  'USDT',
];

function isErc20Token(currency) {
  return erc20Tokens.includes(currency);
}

function isBep20Token(currency) {
  return currency.substr(-6) === '.BEP20';
}

/**
 * Returns URL for exploring given address or transaction
 * @param {string} currency ETH, ETC, USDT, ...
 * @param {string} hash Address or transaction hash
 * @param {string} type 'address' or 'transaction'
 * @return {string|null}
 */
export function getExploreUrl(currency, hash, type = 'address') {
  if (isErc20Token(currency)) {
    currency = 'ETH';
  }
  else if (isBep20Token(currency)) {
    currency = 'BNB';
  }

  const data = exploreUrls[currency];
  const url = data && data[type];
  if (!url) {
    return null;
  }
  return url.replace('{val}', hash);
}
