import React from "react";
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import {BILLING_TYPES} from "constants/types";

const BillingDataSummary = ({source, record}) => {
  const billingData = _.get(record, source, []);
  const typesIds = billingData.map(item => item.type);

  const content = typesIds.join(', ');
  const title = billingData.map(item => {
    const typeName = BILLING_TYPES[item.type];
    return `${typeName} (${item.weight})`;
  }).join(', ');

  return <Typography component='span' title={title}>
    {content}
  </Typography>
};

export default BillingDataSummary;
