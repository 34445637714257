import React, {Fragment} from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  ShowButton,
  Show,
  ReferenceField,
  SimpleShowLayout,
  TabbedShowLayout,
  Tab,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { refreshView } from 'ra-core';
import Icon from '@material-ui/icons/Receipt';

import ReferralBalance from 'components/referrals/ReferralBalance';
import LastPayoutField from 'components/referrals/LastPayoutField';
import ConvertToEthButton from 'components/referrals/ConvertToEthButton';
import ConvertToEthBulkButton from 'components/referrals/ConvertToEthBulkButton';
import PayoutBulkButton from 'components/referrals/PayoutBulkButton';
import TotalUsdField from 'components/referrals/TotalUsdField';
import { CustomPagination } from 'common/pagination/CustomPagination';
import UserAliasField from "fields/UserAliasField";
import SummaryInfo from "components/referrals/SummaryInfo";
import PayoutButton from "components/referrals/PayoutButton";
import ManualPayout from 'components/referrals/ManualPayout';
import JsonField from "fields/JsonField";
import TransactionStatus from "components/referrals/TransactionStatus";
import RetryPayoutButton from "components/referrals/RetryPayoutButton";
import TxidField from "fields/TxidField";
import {CONFIG} from "config";

export const ReferralsIcon = Icon;

const PAYOUT_THRESHOLD = 25;

export const ReferralsFilter = (props) => (
  <Filter { ...props }>
    <TextInput source='id' label='User Id' alwaysOn/>
    <TextInput source='login' alwaysOn/>
    <TextInput source='payout_address' alwaysOn/>
  </Filter>
);

export const ReferralsGrid = (props) => (
  <Datagrid { ...props }>
    <TextField source='id' label='User Id'/>
    <ReferenceField source='id' reference='users' label='Login'>
      <UserAliasField/>
    </ReferenceField>
    <TextField source='name'/>
    <TextField source='payout_address'/>
    <ReferralBalance source='referral_balance' sortable={ false }/>
    <LastPayoutField source='last_payout' sortable={false}/>
    <TotalUsdField source='total_usd' threshold={PAYOUT_THRESHOLD} />
    <ShowButton />
  </Datagrid>
);

export const PayoutsFilter = (props) => (
  <Filter {...props}>
    <TextInput source='user_id' alwaysOn/>
    <TextInput source='txid' label='Transaction hash' alwaysOn/>
  </Filter>
);

export const PayoutsGrid = (props) => (
  <Datagrid { ...props }>
    <TextField source='id'/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <ReferenceField source='user_id' reference='users' allowEmpty={true} label='User'>
      <UserAliasField/>
    </ReferenceField>
    <TextField source='currency'/>
    <TextField source='amount'/>
    <TextField source='amount_fiat' label={`Amount ${CONFIG.fiatCurrency}`} sortable={false}/>
    <TxidField source='txid' currencySource='currency' sortable={false}/>
    <TransactionStatus source='status' label='Transaction status' sortable={false}/>
    <JsonField source='props' sortable={false} preview={false}/>
    <ReferenceField label='Admin' source='props.admin_id' reference='users' allowEmpty={true} sortable={false}>
      <UserAliasField/>
    </ReferenceField>
    <RetryPayoutButton/>
  </Datagrid>
);

export const referralsListProps = {
  sort: { field: 'total_usd', order: 'DESC' },
  // perPage: 1000000,
  // pagination: <CustomPagination rowsPerPageOptions={ [1, 10, 25, 50, 100] }/>,
  // pagination: null,
  pagination: <CustomPagination/>,
  perPage: 50,
};

export const payoutsListProps = {
  sort: {field: 'id', order: 'desc'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

const PostBulkActionButtons = props => (
  <Fragment>
    <ConvertToEthBulkButton {...props}/>
    <PayoutBulkButton {...props}/>
  </Fragment>
);

export const ReferralsList = (props) => (
  <div>
    <SummaryInfo threshold={PAYOUT_THRESHOLD}/>
    <TabbedShowLayout>
      <Tab label='Do payout'>
        <div>
          <List title='Do payout'
                {...props}
                {...referralsListProps}
                filters={<ReferralsFilter/>}
                bulkActionButtons={<PostBulkActionButtons/>}
          >
            <ReferralsGrid/>
          </List>
        </div>
      </Tab>
      <Tab label='Recent payouts'>
        <div>
          <List
            title='Recent payouts'
            {...props}
            {...payoutsListProps}
            filters={<PayoutsFilter/>}
            resource='referrals/payouts'
            bulkActionButtons={false}
          >
            <PayoutsGrid/>
          </List>
        </div>
      </Tab>
    </TabbedShowLayout>
  </div>
);

export class ReferralsShowComponent extends React.Component {
  handleConvertToEthDone = () => {
    const {refreshView} = this.props;
    refreshView();
  };

  handlePayoutDone = () => {
    const {push, basePath} = this.props;
    push(basePath);
  };

  render() {
    const Title = ({record}) => <div>User {record ? `"${record.login}"` : ''}</div>;

    const {push, refreshView, ...restProps} = this.props; // prevent "push" and "refreshView" passing to Show component

    return <Show title={<Title/>} {...restProps}>
      <SimpleShowLayout>
        <ReferralBalance source='referral_balance'/>
        <ConvertToEthButton done={this.handleConvertToEthDone}/>
        <PayoutButton done={this.handlePayoutDone}/>
        <ManualPayout done={this.handlePayoutDone}/>
      </SimpleShowLayout>
    </Show>
  }
}

export const ReferralsShow = connect(null, {push, refreshView})(ReferralsShowComponent);
