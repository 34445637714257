import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { crudUpdate, startUndoable } from 'ra-core';

import BooleanField from 'common/boolean-field/BooleanField';

class AttributeToggle extends Component {
  static propTypes = {
    recordAttribute: PropTypes.string.isRequired,
    requestAttribute: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    enableText: PropTypes.string,
    disableText: PropTypes.string,
    enableValue: PropTypes.bool,
    disableValue: PropTypes.bool,
    noButton: PropTypes.bool,
    editOnTurnedOff: PropTypes.bool,
    onBeforeRequest: PropTypes.func,
    record: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
    translate: PropTypes.func,
    startUndoable: PropTypes.func.isRequired,
  };

  static defaultProps = {
    enableText: 'Enable',
    disableText: 'Reset',
    enableValue: true,
    disableValue: false,
    noButton: true,
    editOnTurnedOff: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: !!props.record[props.recordAttribute]
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({value: !!nextProps.record[nextProps.recordAttribute]})
  }

  getLabel() {
    return (this.state.value === true)
      ? this.props.disableText
      : this.props.enableText;
  }

  handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { record, resource, enableValue, disableValue, startUndoable, onBeforeRequest } = this.props;
    const newValue = !this.state.value;

    const requestData = {
      [this.props.recordAttribute]: newValue,
      [this.props.requestAttribute]: newValue ? enableValue : disableValue,
    };

    if (onBeforeRequest && onBeforeRequest(requestData) === false) {
      return;
    }

    this.setState({value: newValue});
    startUndoable(crudUpdate(resource, record.id, requestData, record, '', false));
  };

  putButton() {
    if (this.props.noButton) {
      return null;
    }

    if (!this.props.editOnTurnedOff && (this.state.value === false)) {
      return null;
    }

    return(
      <Button color='primary' variant='contained' onClick={ this.handleClick.bind(this) }>
        { this.getLabel() }
      </Button>
    );
  }

  render() {
    return (
      <FormControl className={ this.props.classes.main }>
        <BooleanField text={ this.props.labelText } value={ this.state.value }/>
        { this.putButton() }
      </FormControl>
    );
  }
}

const styles = theme => ({
  main: {
    padding: '15px 0',
  },
});

export default connect(null, {
  startUndoable,
})(withStyles(styles)(AttributeToggle));
