import React from 'react';
import PropTypes from 'prop-types';
import AliasField from "./AliasField";

export const userOptionRenderer = user => `${user.id} • ${user.login} (${user.name})`;

const UserAliasField = ({showId, showName, ...restProps}) => {
  return <AliasField
    nameField="login"
    detailsField="name"
    showId={showId}
    showDetails={showName}
    {...restProps}
  />;
};

UserAliasField.propTypes = {
  record: PropTypes.object,

  showId: PropTypes.bool,
  showName: PropTypes.bool,
};

UserAliasField.defaultProps = {
  record: {},
  label: 'User',
  addLabel: true,

  showId: false,
  showName: false,
};

export default UserAliasField;
