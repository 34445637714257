import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {EmailField} from 'react-admin';
import cx from 'classnames';

const UserEmailField = ({record = {}, source, confirmedSource, classes: c, ...restProps}) => {
  if (record[confirmedSource] === undefined || !record[source]) {
    return <EmailField record={record} source={source}/>;
  }

  const cls = c.unconfirmed;
  const confirmed = record[confirmedSource];

  return <div>
    {confirmed ?
      <span className={cx(c.icon, c.confirmed)} title='Email confirmed'>⬤</span> :
      <span className={cx(c.icon, c.unconfirmed)} title='Email not confirmed'>⬤</span>
    }
    <EmailField record={record} source={source} className={cls}/>
  </div>
};

UserEmailField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,

  confirmedSource: PropTypes.string,
};

UserEmailField.defaultProps = {
  record: {},
  source: 'email',
  label: 'Email',
  addLabel: true,

  confirmedSource: 'email_confirmed',
};

const styles = theme => ({
  icon: {
    marginRight: 10,
    marginLeft: 10
  },
  confirmed: {
    color: 'green',
  },
  unconfirmed: {
    color: 'grey',
  }
});

const EnhancedUserEmailField = withStyles(styles)(UserEmailField);

EnhancedUserEmailField.defaultProps = {
  addLabel: true,
};

export default EnhancedUserEmailField;
