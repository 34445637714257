import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import cx from 'classnames';

class DonutTableRow extends Component {
  static propTypes = {
    total: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    }
  }

  handleClick() {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  getPercentage(value) {
    const {total} = this.props;
    let percentage = (value*100/total).toFixed(2);

    if (percentage > 0) {
      return percentage + '%';
    }

    return '<0.01%';
  }

  render() {
    let {item, classes: c, key} = this.props;
    const {expanded} = this.state;
    const expandable = item.details && item.details.length > 0;

    if (!expandable) {
      return <div className={c.row} key={key}>
        <div className={c.name}>{item.name || '???'}</div>
        <div className={c.value}>{item.value}</div>
        <div className={c.percentage}>{this.getPercentage(item.value)}</div>
      </div>;
    } else {
      return [
        // parent row
        <div className={cx(c.row, c.parentRow)}
             title={expanded ? 'Collapse' : 'Expand'}
             onClick={this.handleClick.bind(this)}
             key={item.name}>
          <div className={c.name}>
            {expanded ?
              <span className={c.icon}>&#x252C;</span> :
              <span className={c.icon}>&#x2500;</span>}
            {item.name || '???'}
          </div>
          <div className={c.value}>{item.value}</div>
          <div className={c.percentage}>{this.getPercentage(item.value)}</div>
        </div>,

        // children rows
        ...(expanded ? item.details.map((ditem, dindex) => {
          const latest = dindex === item.details.length - 1;

          return <div className={cx(c.row, c.drow)} key={`${key}.${dindex}`}>
            <div className={cx(c.name, c.dname)}>
              {latest ?
                <span className={c.dicon}>&#x2514;</span> :
                <span className={c.dicon}>&#x251C;</span>}
              {ditem.name || '???'}
            </div>
            <div className={c.value}>{ditem.value}</div>
            <div className={c.percentage}>{this.getPercentage(ditem.value)}</div>
          </div>
        }) : [])
      ];
    }
  }
}

const styles = theme => ({
  row: {
    display: 'flex',
    padding: '2px 5px',
    borderBottom: '1px solid #bbb',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    }
  },
  parentRow: {
    cursor: 'pointer',
  },
  name: {
    flex: 5,
    textAlign: 'left',
    overflowX: 'hidden',
    textOverflow: 'elipsys',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
  value: {
    flex: 2,
    textAlign: 'right',
  },
  percentage: {
    flex: 2,
    textAlign: 'right',
  },
  drow: {},
  dname: {
    '&::before': {
      content: '--- ',
      display: 'inline',
    }
  },
  dicon: {
    marginRight: 20,
  },
});

export default withStyles(styles)(DonutTableRow);
