import React from 'react';
import PropTypes from 'prop-types';

const CustomSelectField = (props) => {
  const {record, source, choices, optionText, optionValue} = props;
  const id = record[source];
  const choice = choices.find(c => c[optionValue] === id);
  if (!choice) {
    return id;
  }
  const name = choice[optionText] || null;
  return <span title={ id }>{ name }</span>
};

CustomSelectField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
};

CustomSelectField.defaultProps = {
  record: {},
  optionText: 'name',
  optionValue: 'id',
  addLabel: true,
};

export default CustomSelectField;
