import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  Filter,
  TextInput,
  ReferenceField,
  DeleteButton,
  NullableBooleanInput,
  Show,
  SimpleShowLayout, ShowButton,
} from 'react-admin'
import JsonField from 'fields/JsonField';
import AdaptiveLayout from "common/layout/AdaptiveLayout";
import Icon from '@material-ui/icons/VpnKey';

import {CustomPagination} from "../common/pagination/CustomPagination";
import IPField from "fields/IPField";
import UserAliasField from "../fields/UserAliasField";

export const TokensIcon = Icon;

export const TokensFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    <TextInput label='ID' source='id' alwaysOn/>
    <TextInput label='IP address' source='ip' alwaysOn/>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    <TextInput label='Token string' source='token' alwaysOn/>
    <NullableBooleanInput label='Personal' source='personal' alwaysOn/>
  </Filter>
);

export const TokensGrid = (({consumer, classes: c, permissions: p, ...props}) => {
  return <Datagrid {...props}>
    <TextField source='id'/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    <BooleanField source='personal'/>
    <TextField source='name'/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <DateField source='expires_at' showTime locales='uk-UA'/>
    <DateField source='last_activity' showTime locales='uk-UA'/>
    <IPField source='ip'/>
    <BooleanField source='active'/>
    <JsonField source='props' sortable={false} preview={false}/>
    {p && p['admin.tokens.manage'] && <DeleteButton basePath={''}/>}
    <ShowButton/>
  </Datagrid>
});

export const TokensShow = (props) => (
  <Show {...props}>
    {/*<AdaptiveLayout>*/}
      <SimpleShowLayout {...props}>
        <TextField source='id'/>
        <TextField source='name'/>
        <BooleanField source='personal'/>
        <BooleanField source='active'/>
        <TextField source='ip'/>
        <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
          <UserAliasField/>
        </ReferenceField>
        <DateField source='created_at' showTime locales='uk-UA'/>
        <DateField source='expires_at' showTime locales='uk-UA'/>
        <DateField source='last_activity' showTime locales='uk-UA'/>
        <JsonField source='props' collapsed={false} buttons={false}/>
      </SimpleShowLayout>
    {/*</AdaptiveLayout>*/}
  </Show>
);

export const tokensListProps = {
  sort: {field: 'id', order: 'DESC'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

const TokensList = ({permissions, ...props}) => (
  <List
    title='Tokens'
    {...props}
    {...tokensListProps}
    filters={<TokensFilter/>}
    bulkActionButtons={false}
  >
    <TokensGrid permissions={permissions}/>
  </List>
);

export default TokensList;
