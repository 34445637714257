import React from "react";
import _ from 'lodash';
import LockIcon from '@material-ui/icons/Lock';
import YesNoField from "fields/YesNoField";

const IsBannedField = ({record, source, showComment, commentSource, showNo}) => {
  const comment = _.get(record, commentSource, '');
  return <YesNoField source={source}
                     record={record}
                     comment={comment}
                     showComment={showComment}
                     yesText={<LockIcon/>}
                     showNo={showNo}/>
};

IsBannedField.defaultProps = {
  showComment: false,
  commentSource: 'banned_at',
  showNo: true,

  source: 'banned',
  label: 'Is banned',
  addLabel: true,
};

export default IsBannedField;