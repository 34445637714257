import React from 'react';
import {
  List,
  Filter,
  TextInput,
  NullableBooleanInput,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  ReferenceField,
  BooleanField,
} from 'react-admin';

import Icon from '@material-ui/icons/People';
import { CustomPagination } from 'common/pagination/CustomPagination';
import JsonField from "fields/JsonField";
import UserAliasField from "fields/UserAliasField";
import {CONFIG} from "config";
import YesNoField from "fields/YesNoField";
import IsBannedField from "../components/users/IsBannedField";
import UserEmailField from "fields/UserEmailField";

export const UsersIcon = Icon;

export const UsersFilter = ({consumer, ...props}) => (
  <Filter { ...props }>
    <TextInput label='ID' source='id' alwaysOn/>
    <TextInput label='Login' source='login' alwaysOn/>
    <TextInput label='Email' source='email' alwaysOn/>
    {consumer !== 'users' && <TextInput label='Referral ID' source='referral_id' />}
    <NullableBooleanInput source='banned' />
    <TextInput source='name' />
    <TextInput source='props' />
    <TextInput source='promocode' />
    <NullableBooleanInput source='all_pools' />
    <NullableBooleanInput source='business' />
    <NullableBooleanInput source='activated' />
  </Filter>
);

export const UsersGrid = ({permissions: p, consumer, ...props}) => (
  <Datagrid { ...props }>
    <TextField source='id' />
    <TextField source='name' />
    <TextField source='login' />
    <UserEmailField source='email' />
    {consumer !== 'users' && <ReferenceField label='Referral' source='referral_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    <YesNoField source='has_twofa' label='2FA' />
    <YesNoField source='has_whitelist' label='Whitelist' />
    <NumberField source='balance' options={ {style: 'currency', currency: CONFIG.fiatCurrency} } />
    <NumberField source='discount' options={ {style: 'percent'} } />
    <DateField source='created_at' showTime locales='uk-UA' />
    <IsBannedField label='Banned' showNo={false}/>
    <YesNoField source='all_pools' />
    <YesNoField source='business' />
    <BooleanField source='activated'/>
    <JsonField source='props' sortable={ false } preview={ false } />
    {p && p['admin.users.manage.*'] && <EditButton/>}
  </Datagrid>
);

export const usersListProps = {
  sort: {field: 'id', order: 'ASC'},
  pagination: <CustomPagination />,
  perPage: 50,
};

const UsersList = ({permissions, ...props}) => (
  <List
    title='Users'
    { ...props }
    { ...usersListProps }
    filters={ <UsersFilter /> }
    bulkActionButtons={ false }
  >
    <UsersGrid permissions={permissions} />
  </List>
);

export default UsersList;
