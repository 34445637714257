import React from "react";
import PropTypes from 'prop-types';
import cx from "classnames";
import {NumberField} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';

const BalanceField = (props) => {
  const {record, source, real_source, classes: c, ...restProps} = props;
  const val = +record[source];
  const realVal = +record[real_source];
  const className = Math.abs(realVal - val) > 1e-10 ? 'err' : null;
  return <NumberField
    {...restProps}
    record={record}
    source={source}
    options={{maximumFractionDigits: 10}}
    title={`Real balance: ${realVal}`}
    className={className ? c[className] : null}
  />
};

BalanceField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

BalanceField.defaultProps = {
  record: {},
  source: 'balance',
  real_source: 'real_balance',
};

const styles = theme => ({
  err: {
    color: '#ff3e48',
  },
});

const EnhancedBalanceField = withStyles(styles)(BalanceField);

EnhancedBalanceField.defaultProps = {
  addLabel: true,
};

export default EnhancedBalanceField;
