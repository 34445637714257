import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
} from 'react-admin';
import {CustomPagination} from "common/pagination/CustomPagination";
import UserAliasField from "fields/UserAliasField";
import Withdraw from "components/deposit-wallets/Withdraw";
import JsonField from "fields/JsonField";
import TxidField from "fields/TxidField";
import TransactionStatus from "components/referrals/TransactionStatus";
import {CONFIG} from "config";

export {default as GethIcon} from '@material-ui/icons/AttachMoney';

const WithdrawalsGrid = ({consumer, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    <ReferenceField label='Who' source='user_id' reference='users' allowEmpty={true} sortable={false}>
      <UserAliasField/>
    </ReferenceField>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <TextField source='currency'/>
    <TextField source='amount'/>
    <TextField source='amount_fiat' label={`Amount ${CONFIG.fiatCurrency}`} sortable={false}/>
    <TxidField source='txid' currencySource='currency' sortable={false}/>
    <TransactionStatus source='status' label='Transaction status' sortable={false}/>
    <JsonField source='props' sortable={false} preview={false}/>
  </Datagrid>
);

export const GethView = ({permissions, ...props}) => (
  <div>
    <TabbedShowLayout>
      {permissions && permissions['admin.geth.withdraw'] && <Tab label='Withdraw funds'>
        <Withdraw/>
      </Tab>}
      {permissions && permissions['admin.geth.withdraw'] && <Tab label='Withdrawals'>
        <div>
          <List
            title='Withdrawals'
            {...props}
            sort={{field: 'id', order: 'desc'}}
            pagination={<CustomPagination/>}
            perPage={50}
            bulkActionButtons={false}
            resource='geth/withdrawals'
          >
            <WithdrawalsGrid/>
          </List>
        </div>
      </Tab>}
    </TabbedShowLayout>
  </div>
);

