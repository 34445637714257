import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core/styles';
import ucfirst from 'ucfirst';

const DEFAULT_DATA = [
  { key: 'Users', value: '-', href: '/users' },
  { key: 'Farms', value: '-', href: '/farms' },
  { key: 'Workers', value: '-', href: '/workers' },
  { key: 'GPUs', value: '-', href: '/total_stats' },
  { key: 'CPUs', value: '-', href: '/total_stats' },
  { key: 'ASICs', value: '-' , href: '/total_stats'},
];

class GeneralInfo extends Component {
  static propTypes = {
    data: PropTypes.array,
    variant: PropTypes.oneOf(['row', 'cards']),
    push: PropTypes.func,
  };

  static defaultProps = {
    variant: 'row'
  };

  getClass(key, variant, classes) {
    return classes[variant+ucfirst(key)] || '';
  }

  handleClick(href) {
    if (!href) {
      return;
    }

    this.props.push(href);
  }

  render() {
    const data = this.props.data || DEFAULT_DATA;
    const classes = this.props.classes;

    return (
      <div className={ classes.root }>
        { data.map((item, index) => {
          return (
            <div
              key={ index }
              className={ this.getClass('item', this.props.variant, classes) }
              onClick={ this.handleClick.bind(this, item.href) }
            >
              <div className={ this.getClass('wrapper', this.props.variant, classes) }>
                <div className={ this.getClass('key', this.props.variant, classes) }>
                  { item.key }
                </div>
                <div className={ this.getClass('value', this.props.variant, classes) }>
                  <span className={ this.getClass('valueBack', this.props.variant, classes) }>
                    { item.value }
                  </span>
                </div>
              </div>
            </div>
          );
        }) }
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    color: theme.colors.text,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: { // eslint-disable-line no-useless-computed-key
      flexDirection: 'row',
    }
  },
  rowItem: {
    flex: 1,
    padding: '5px',
    width: '50%',
  },
  rowWrapper: {
    display: 'flex',
  },
  rowKey: {
    flex: 1,
    textAlign: 'right',
    padding: '0 4px',
  },
  rowValue: {
    flex: 1,
    textAlign: 'left',
    fontSize: '16px',
    color: '#565656',
  },
  rowValueBack: {
    border: '1px solid #bdbdbd',
    padding: '3px 8px',
    borderRadius: '5px',
    background: theme.colors.rounding,
  },
  cardsItem: {
    flex: '1 1 25%',
    padding: '25px',
    margin: '15px',
    borderRadius: '5px',
    transition: 'background-color 0.5s ease',
    backgroundColor: theme.colors.back,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    },
  },
  cardsWrapper: {
    display: 'flex',
    flexFlow: 'column',
    [theme.breakpoints.up('md')]: { // eslint-disable-line no-useless-computed-key
      flexFlow: 'row',
    }
  },
  cardsKey: {
    flex: 1,
    textAlign: 'left',
    padding: '5px 5px 15px',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: { // eslint-disable-line no-useless-computed-key
      padding: '15px 5px 5px',
    }
  },
  cardsValue: {
    flex: 1,
    textAlign: 'left',
    fontSize: '36px',
    color: '#565656',
  },
  cardsValueBack: {
    border: '1px solid #bfbfbf',
    background: theme.colors.rounding,
    padding: '3px 15px',
    borderRadius: '5px',
  }
});

export default connect(null, {
  push,
})(withStyles(styles)(GeneralInfo));
