import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

class DonutsContainer extends Component {
  render() {
    return (
      <div className={ this.props.classes.main }>
        { this.props.children }
      </div>
    );
  }
}

const styles = theme => ({
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: { // eslint-disable-line no-useless-computed-key
      flexDirection: 'column',
    }
  }
});

DonutsContainer.propTypes = {
  children: PropTypes.array.isRequired,
};

export default withStyles(styles)(DonutsContainer);
