import React from 'react';
import PropTypes from 'prop-types';
import AliasField from "./AliasField";
import {CONFIG} from "../config";
import UrlField from "./UrlField";

const WorkerAliasField = ({showId, showName, link, ...restProps}) => {
  const aliasField = <AliasField
    showId={showId}
    {...restProps}
  />;
  if (link) {
    const {record} = restProps;
    const r = {content: aliasField, id: record.id, farm_id: record.farm_id};
    return <UrlField
      record={r}
      source='content'
      hrefpattern={`${CONFIG.productUrl}/farms/{farm_id}/workers/{id}`}
      title={'Open worker in ' + CONFIG.productUrl}
    />
  }
  return aliasField;
};

WorkerAliasField.propTypes = {
  record: PropTypes.object,

  showId: PropTypes.bool,
  link: PropTypes.bool,
};

WorkerAliasField.defaultProps = {
  record: {},
  label: 'Worker',
  addLabel: true,

  showId: false,
  link: false,
};

export default WorkerAliasField;
