import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  SelectInput,
  ReferenceField,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import JsonField from 'fields/JsonField';
import Icon from '@material-ui/icons/VpnKey';
import {CustomPagination} from "../common/pagination/CustomPagination";
import CustomSelectField from "fields/CustomSelectField";
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import {API_KEY_SOURCE_TYPES} from "../constants/types";

const sourceTypes = Object.keys(API_KEY_SOURCE_TYPES).map(id => ({id: id, name: API_KEY_SOURCE_TYPES[id]}));

export const ApiKeysIcon = Icon;

export const ApiKeysFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    <TextInput label='ID' source='id' alwaysOn/>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    {consumer !== 'farms' && <TextInput label='Farm ID' source='farm_id' alwaysOn/>}
    <SelectInput source='source_type' alwaysOn choices={sourceTypes}/>
    <TextInput source='source_name' alwaysOn/>
    <TextInput source='api_key' alwaysOn/>
    <TextInput source='api_id' label='API ID'/>
    <TextInput source='props'/>
  </Filter>
);

export const ApiKeysGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    <TextField source='name'/>
    <CustomSelectField source='source_type' choices={sourceTypes}/>
    <TextField source='source_name'/>
    <TextField source='api_key' label='API Key'/>
    <TextField source='api_id' label='API ID'/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
      <FarmAliasField/>
    </ReferenceField>}
    <DateField source='created_at' showTime locales='uk-UA'/>
    <JsonField source='props' sortable={false} preview={false}/>
  </Datagrid>
);

export const ApiKeysShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='id'/>
      <TextField source='name'/>
      <CustomSelectField source='source_type' choices={sourceTypes}/>
      <TextField source='source_name'/>
      <TextField source='api_key' label='API Key'/>
      <TextField source='api_id' label='API ID'/>
      {
        (props.consumer !== 'users') &&
        <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
          <UserAliasField/>
        </ReferenceField>
      }
      {
        (props.consumer !== 'farms') &&
        <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
          <FarmAliasField/>
        </ReferenceField>
      }
      <DateField source='created_at' showTime locales='uk-UA'/>
      <JsonField source='props' collapsed={false} buttons={false}/>
    </SimpleShowLayout>
  </Show>
);

export const apiKeysListProps = {
  sort: {field: 'id', order: 'asc'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

export const ApiKeysList = ({permissions, ...props}) => (
  <List
    title='API Keys'
    {...props}
    {...apiKeysListProps}
    filters={<ApiKeysFilter/>}
    bulkActionButtons={false}
  >
    <ApiKeysGrid permissions={permissions}/>
  </List>
);
