import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AttributeToggle from 'common/attribute-toggle/AttributeToggle';

export default class ResetWhitelist extends Component {
  static propTypes = {
    noButton: PropTypes.bool,
  }

  static defaultProps = {
    noButton: true,
  }

  render() {
    const { props } = this;

    return (
      <AttributeToggle
        recordAttribute='has_whitelist'
        requestAttribute='reset_whitelist'
        labelText='Has whitelist'
        enableText=''
        disableText='Reset whitelist'
        disableValue={ true }
        noButton={ props.noButton }
        editOnTurnedOff={ false }
        record={ props.record }
        resource={ props.resource }
        translate={ props.translate }
      />
    );
  }
}
