import React from "react";
import {
  ChipField,
} from "react-admin";

const PermissionChip = ({record, ...props}) => {
  const rec = {
    __chip: <span title={record.description}>{record.display_name}</span>,
    ...record
  };
  return <ChipField source="__chip" record={rec} {...props}/>;
};

export default PermissionChip;
