import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  ReferenceField,
  BooleanField,
  NullableBooleanInput
} from 'react-admin';
import JsonField from 'fields/JsonField';
import Icon from '@material-ui/icons/AttachMoney';
import {CustomPagination} from "../common/pagination/CustomPagination";
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import {CONFIG} from "config";

export const CoinpaymentsIcon = Icon;

export const CoinpaymentsFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    <TextInput label='Currency' source='currency' alwaysOn/>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    {consumer !== 'farms' && <TextInput label='Farm ID' source='farm_id' alwaysOn/>}
    <NullableBooleanInput label='Completed' source='completed' alwaysOn/>
    <TextInput label='Transaction ID' source='txn_id' alwaysOn/>
    <TextInput source='props' alwaysOn/>
  </Filter>
);

const AmountField = (props) => {
  const {
    source,
    record = {},
    currency = null,
    currencySource = null
  } = props;
  const cur = currencySource ? record[currencySource] : currency;
  return <span>{`${record[source]} ${cur}`}</span>;
};

const StatusField = ({source, record = {}, titleSource = null}) => <span title={record[titleSource]}>{record[source]}</span>;

export const CoinpaymentsGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <TextField source='txn_id'/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
      <FarmAliasField/>
    </ReferenceField>}
    <AmountField source='amount' currencySource='currency' sortable={false}/>
    <AmountField source='amount_usd' currency={CONFIG.fiatCurrency} label='Amount' sortable={false}/>
    <BooleanField source='completed'/>
    <StatusField source='status' titleSource='status_text' sortable={false}/>
    <JsonField source='props' sortable={false}/>
  </Datagrid>
);

export const coinpaymentsListProps = {
  sort: {field: 'id', order: 'desc'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

const CoinpaymentsList = ({permissions, ...props}) => (
  <List
    title='Coinpayments'
    {...props}
    {...coinpaymentsListProps}
    filters={<CoinpaymentsFilter/>}
    bulkActionButtons={false}
  >
    <CoinpaymentsGrid permissions={permissions}/>
  </List>
);

export default CoinpaymentsList
