import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  Filter,
  TextInput,
  ReferenceField,
  ReferenceArrayField,
} from 'react-admin';
import JsonField from 'fields/JsonField';
import Icon from '@material-ui/icons/Schedule';
import {CustomPagination} from "common/pagination/CustomPagination";
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import RRuleField from "fields/RRuleField";
import SortedSingleFieldList from "common/lists/SortedSingleFieldList";
import {TagChip} from "components/tags/TagChip";

export const SchedulesIcon = Icon;

export const SchedulesFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    {consumer !== 'farms' && <TextInput label='Farm ID' source='farm_id' alwaysOn/>}
    <TextInput source='props'/>
  </Filter>
);

export const SchedulesGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
      <FarmAliasField/>
    </ReferenceField>}
    <TextField source='name'/>
    <TextField source='fs_id' label='FS ID'/>
    <ReferenceArrayField label='Tags' reference='tags' source='tag_ids' sortable={false}>
      <SortedSingleFieldList sortBy='id' linkType='show'>
        <TagChip/>
      </SortedSingleFieldList>
    </ReferenceArrayField>
    <DateField source='launch_at' showTime locales='uk-UA'/>
    <RRuleField source='rrule' label='RRule'/>
    <DateField source='prev_launch_at' showTime locales='uk-UA'/>
    <DateField source='next_launch_at' showTime locales='uk-UA'/>
    <BooleanField source='active'/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <JsonField source='props' sortable={false} preview={false}/>
  </Datagrid>
);

export const schedulesListProps = {
  sort: {field: 'id', order: 'asc'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

export const SchedulesList = ({permissions, ...props}) => (
  <List
    title='Schedules'
    {...props}
    {...schedulesListProps}
    filters={<SchedulesFilter/>}
    bulkActionButtons={false}
  >
    <SchedulesGrid permissions={permissions}/>
  </List>
);

