import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import cx from 'classnames';

class JsonField extends React.Component {
  static propTypes = {
    // initially collapsed, default true
    collapsed: PropTypes.bool,

    // display "show"/"hide" buttons, default true
    buttons: PropTypes.bool,

    // show small preview and "expand" button, default true
    preview: PropTypes.bool,

    // don't limit max width, default false
    wide: PropTypes.bool,

    // max width override
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),


    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
  };

  static defaultProps = {
    collapsed: true,
    buttons: true,
    preview: true,
    wide: false,
    maxWidth: null,
  };

  state = {
    collapsed: true,
  };

  componentDidMount() {
    const {collapsed} = this.props;
    this.setState({collapsed: !!collapsed});
  }

  handleToggleClick = () => {
    const {collapsed} = this.state;
    this.setState({collapsed: !collapsed});
  };

  render() {
    const {source, record = {}, buttons, classes: c, preview, wide, maxWidth} = this.props;
    const {collapsed} = this.state;
    const value = _.get(record, source);
    if (!value || _.isEmpty(value)) {
      return null;
    }
    const content = JSON.stringify(value, null, 2);
    const style = {
      maxWidth,
    };
    const expandText = preview ? 'expand' : 'show';
    const collapseText = preview ? 'collapse' : 'hide';
    const className = cx(c.json, {
      [c.jsonWide]: wide,
      [c.jsonCollapsed]: collapsed,
    });
    return <Typography component="span" className={cx(c.main, {[c.mainExpanded]: !collapsed})}>
      {buttons && collapsed && <span onClick={this.handleToggleClick} className={c.btn}>{expandText}</span>}
      {buttons && !collapsed && <span onClick={this.handleToggleClick} className={c.btn}>{collapseText}</span>}

      {(preview || !collapsed) && <span className={className} style={style}>{content}</span>}

      {buttons && !collapsed && <span onClick={this.handleToggleClick} className={c.btn}>{collapseText}</span>}
    </Typography>
  }
}

const styles = theme => ({
  main: {
    display: 'flex',
  },
  mainExpanded: {
    flexDirection: 'column',
  },
  json: {
    maxWidth: '400px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    lineHeight: '14px',
    fontFamily: 'monospace',
  },
  jsonWide: {
    maxWidth: 'initial',
  },
  jsonCollapsed: {
    height: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  toggle: {
    display: 'block',
    cursor: 'pointer',
    position: 'relative',
  },
  btn: {
    cursor: 'pointer',
    marginRight: '10px',
    color: theme.colors.controlPrimary,
  },
});

JsonField = withStyles(styles)(JsonField);

JsonField.defaultProps = {
  addLabel: true,
};

export default JsonField;
