import React from "react";
import PropTypes from 'prop-types';
import {fetchEnd, fetchStart, showNotification} from "react-admin";
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import PayoutButton from "./PayoutButton";
import {CONFIG} from "config";

class ManualPayout extends React.Component {
	static propTypes = {
		done: PropTypes.func,
		record: PropTypes.object.isRequired,
	};

	state = {
		amount: 0,
		amountUsd: 0,
		txid: '',
	};

	componentDidMount() {
		const {ETH: amount = 0} = this.props.record.referral_balance;
		this.setAmount(amount);
	}

	componentWillReceiveProps(nextProps) {
		const {ETH: amount = 0} = nextProps.record.referral_balance;
		if (amount && amount !== this.state.amount) {
			this.setAmount(amount);
		}
	}

	setAmount(amount) {
		const {rate_eth: rate = 0} = this.props.record;
		const amountUsd = Math.round((amount * rate)*100)/100;
		this.setState({amount, amountUsd});
	}

	handleChange = name => event => {
		if (name === 'amount') {
			this.setAmount(event.target.value);
		}
		else {
			this.setState({
				[name]: event.target.value,
			});
		}
	};

	render() {
		const {record, classes: c, done} = this.props;
		const {payout_address: payoutAddress, rate_eth: rate} = record;
		const {amount, amountUsd, txid} = this.state;
		return <div>
			<div>
				<TextField
					label="Payout address"
					value={payoutAddress}
					className={c.addressInput}
					margin="normal"
					InputProps={{
						readOnly: true,
					}}
				/>
			</div>
			<div>
				<TextField
					label="ETH amount"
					value={amount}
					className={c.amountInput}
					onChange={this.handleChange('amount')}
					margin="normal"
				/>
				<TextField
					label={`${CONFIG.fiatCurrency} amount`}
					value={amountUsd}
					className={c.amountInput}
					margin="normal"
					InputProps={{
						readOnly: true,
					}}
				/>
			</div>
			<div>
				<TextField
					label="Transaction ID"
					value={txid}
					className={c.txidInput}
					onChange={this.handleChange('txid')}
					margin="normal"
				/>
			</div>
			<div>
				<PayoutButton done={done} params={{address: payoutAddress, amount, rate, txid}} record={record}>
					Manual payout
				</PayoutButton>
			</div>
		</div>;
	}
}

const styles = theme => ({
	amountInput: {
		width: 300,
	},
	addressInput: {
		width: 600,
	},
	txidInput: {
		width: 1000,
	},
});

export default connect(null, {
	showNotification, fetchStart, fetchEnd
})(withStyles(styles)(ManualPayout));
