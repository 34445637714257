import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import cx from 'classnames';

const styles = theme => ({
  root: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
  }
});

const PreField = ({record = {}, source, classes, className}) => (
  <Typography component="span" className={cx(classes.root, className)}>{record[source]}</Typography>
);

PreField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const EnhancedPreField = withStyles(styles)(PreField);

EnhancedPreField.defaultProps = {
  record: {},
  addLabel: true,
};

export default EnhancedPreField;
