import React from 'react';
import PropTypes from 'prop-types';

const IPField = ({ record, source }) => {
  const href = `http://ip-api.com/#${ record[source] }`;
  return (
    <a href={ href } target="_blank" title="Show IP geolocation">
      { record[source] }
    </a>
  );
};

IPField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

IPField.defaultProps = {
  record: {},
  addLabel: true,
};

export default IPField;
