import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  SelectInput,
  ReferenceField
} from 'react-admin';
import JsonField from 'fields/JsonField';
import Icon from '@material-ui/icons/History';
import {CustomPagination} from "../common/pagination/CustomPagination";
import IPField from "fields/IPField";
import UserAliasField from "fields/UserAliasField";
import TokenAliasField from "fields/TokenAliasField";

export const AccessLogIcon = Icon;

const methods = ['POST', 'PATCH', 'DELETE'];
const methodsOptions = methods.map(method => ({id: method, name: method}));

export const AccessLogFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    <TextInput label='Admin ID' source='admin_id' alwaysOn/>
    <TextInput label='IP address' source='ip' alwaysOn/>
    <SelectInput label='Method' source='method' choices={methodsOptions} alwaysOn/>
    <TextInput label='Path' source='path' alwaysOn/>
    <TextInput label='Data' source='data' alwaysOn/>
    <TextInput label='Headers' source='headers'/>
    <TextInput label='Props' source='props'/>
    <TextInput label='API version' source='api_version'/>
    <TextInput label='Token ID' source='token_id' alwaysOn/>
  </Filter>
);

export const AccessLogGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    <IPField source='ip'/>
    <TextField source='api_version' label='API ver'/>
    <TextField source='method'/>
    <TextField source='path'/>
    <JsonField source='query' sortable={false} preview={false}/>
    <JsonField source='data' sortable={false} preview={false}/>
    <JsonField source='headers' sortable={false} preview={false}/>
    <JsonField source='props' sortable={false} preview={false}/>
    <ReferenceField label='Token' source='token_id' reference='tokens' linkType='show' allowEmpty={true}>
      <TokenAliasField/>
    </ReferenceField>
    <ReferenceField label='Admin' source='admin_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>
  </Datagrid>
);

export const accessLogListProps = {
  sort: {field: 'id', order: 'desc'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

const AccessLogList = ({permissions, ...props}) => (
  <List
    title='Access Log'
    {...props}
    {...accessLogListProps}
    filters={<AccessLogFilter/>}
    bulkActionButtons={false}
  >
    <AccessLogGrid permissions={permissions}/>
  </List>
);

export default AccessLogList;
