import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import PreField from "fields/PreField";

const ConfigsView = ({record, source, classes, ...props}) => {
  const configs = record[source] || {};
  return <div className={classes.root}>
    {Object.keys(configs).map(key =>
      <fieldset key={key} className={classes.fieldset}>
        <legend>{key}</legend>
        <PreField source={key} record={configs} {...props}/>
      </fieldset>
    )}
  </div>
};

ConfigsView.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

ConfigsView.defaultProps = {
  record: {},
  label: "",
  addLabel: false,
};

const styles = theme => ({
  root: {
    paddingTop: '16px',
  },
  fieldset: {
    padding: '16px',
    marginTop: '16px',
    '&:first-child': {
      marginTop: 0,
    }
  }
});

export default withStyles(styles)(ConfigsView);
