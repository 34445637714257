import * as React from "react";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {CheckboxGroupInput} from 'react-admin';

/*
This component just renders checkboxes in single column
 */

const styles = theme => ({
  root: {
    '& > div': {
      flexDirection: 'column',
    },
  }
});

class CustomCheckboxGroupInput extends React.Component {
  render() {
    const {classes} = this.props;
    return <CheckboxGroupInput {...this.props} className={classes.root}/>
  }
}

CustomCheckboxGroupInput.propTypes = {

};

export default withStyles(styles)(CustomCheckboxGroupInput);
