import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AttributeToggle from 'common/attribute-toggle/AttributeToggle';

export default class IsBusiness extends Component {
  static propTypes = {
    noButton: PropTypes.bool,
  };

  static defaultProps = {
    noButton: true,
  };

  render() {
    const { props } = this;

    return (
      <AttributeToggle
        recordAttribute='business'
        requestAttribute='business'
        labelText='Is business'
        enableText='Enable'
        disableText='Disable'
        disableValue={ false }
        noButton={ props.noButton }
        editOnTurnedOff={ true }
        record={ props.record }
        resource={ props.resource }
        translate={ props.translate }
      />
    );
  }
}
