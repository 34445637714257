import React from 'react';
import PropTypes from 'prop-types';

const AliasField = (props) => {
  const {record, nameField, showId, idField, showDetails, detailsField} = props;
  if (!record) {
    return null;
  }
  let text = record[nameField];

  const id = record[idField] || '';
  if (showId && id !== '') {
    text = `${id} • ${text}`;
  }

  const details = record[detailsField] || '';
  if (showDetails && details !== '') {
    text = `${text} (${details})`;
  }

  return (
    <span title={id !== '' ? `ID: ${id}` : ''}>{text}</span>
  );
};

AliasField.propTypes = {
  record: PropTypes.object,

  nameField: PropTypes.string,

  showId: PropTypes.bool,
  idField: PropTypes.string,

  showDetails: PropTypes.bool,
  detailsField: PropTypes.string,
};

AliasField.defaultProps = {
  record: {},
  addLabel: true,

  nameField: 'name',

  showId: false,
  idField: 'id',

  showDetails: false,
  detailsField: '',
};

export default AliasField;
