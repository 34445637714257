import React from "react";
import {
  ChipField,
} from "react-admin";
import UserAliasField from "fields/UserAliasField";

const UserChip = ({record, ...props}) => {
  const rec = {
    __chip: <UserAliasField record={record}/>,
    ...record
  };
  return <ChipField source="__chip" record={rec} {...props}/>;
};

export default UserChip;
