import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  SelectInput,
  ReferenceField,
  ShowButton,
} from 'react-admin';
import Icon from '@material-ui/icons/Label';
import {CustomPagination} from "../common/pagination/CustomPagination";
import CustomSelectField from "fields/CustomSelectField";
import UserAliasField from "fields/UserAliasField";
import FarmAliasField from "fields/FarmAliasField";
import TagField from "fields/TagField";
import {TAG_TYPES} from "../constants/types";

const tagTypes = Object.keys(TAG_TYPES).map(id => ({id: +id, name: TAG_TYPES[id]}));

export const TagsIcon = Icon;

export const TagsFilter = ({consumer, ...props}) => (
  <Filter {...props}>
    {consumer !== 'users' && <TextInput label='User ID' source='user_id' alwaysOn/>}
    {consumer !== 'farms' && <TextInput label='Farm ID' source='farm_id' alwaysOn/>}
    <SelectInput source='type_id' alwaysOn choices={tagTypes}/>
    <TextInput source='name' alwaysOn/>
  </Filter>
);

export const TagsGrid = ({consumer, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    {consumer !== 'users' && <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>}
    {consumer !== 'farms' && <ReferenceField label='Farm' source='farm_id' reference='farms' allowEmpty={true}>
      <FarmAliasField/>
    </ReferenceField>}
    <TagField source='name'/>
    <CustomSelectField source='type_id' choices={tagTypes}/>
    <TextField source='workers_count' sortable={false}/>
    <TextField source='farms_count' sortable={false}/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    <ShowButton/>
  </Datagrid>
);

export const tagsListProps = {
  sort: {field: 'id', order: 'asc'},
  pagination: <CustomPagination/>,
  perPage: 50,
};

export const TagsList = ({permissions, ...props}) => (
  <List
    title='Tags'
    {...props}
    {...tagsListProps}
    filters={<TagsFilter/>}
    bulkActionButtons={false}
  >
    <TagsGrid permissions={permissions}/>
  </List>
);
